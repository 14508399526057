import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'

var lodash = require('lodash')

class Utilisateurs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchingUtilisateurs: false,
      utilisateurs: [],
      filterUtilisateursName: '',
      updateInProgress: [],
      configEsellerLead: null,
      fetchingProfilsLeads: false,
      profilsLead: []
    }
  }

  componentWillMount() {
    this.fetchUtilisateurs()
    this.fetchProfilLead()
    this.fetchConfigEsellerLead()
  }

  fetchConfigEsellerLead() {
    fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        configEsellerLead: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchUtilisateurs() {
    this.setState({
      fetchingUtilisateurs: true
    });
    fetch(localStorage.backoffice_api + '/api/utilisateurs', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var utilisateurs = lodash.sortBy(json, (utilisateur) => {
        return utilisateur.Nom;
      })

      this.setState({
        utilisateurs: utilisateurs,
        fetchingUtilisateurs: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchProfilLead() {
    this.setState({
      fetchingProfilsLeads: true
    });
    fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/types', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        profilsLead: json,
        fetchingProfilsLeads: false,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(utilisateur, event) {

    var updateInProgress = this.state.updateInProgress;
    this.state.updateInProgress.push(utilisateur.IDPeople);

    this.setState({
      updateInProgress: updateInProgress,
    })

    if (event.target.name === 'TypeVO') {
      utilisateur.TypeVO = event.target.checked;
    }
    else if (event.target.name === 'TypeVN') {
      utilisateur.TypeVN = event.target.checked;
    }
    else if (event.target.name === 'TypeVDL') {
      utilisateur.TypeVDL = event.target.checked;
    }
    else if (event.target.name === 'TypeConseiller') {
      utilisateur.TypeConseiller = event.target.checked;
    }
    else if (event.target.name === 'TypeAutre') {
      utilisateur.TypeAutre = event.target.checked;
    }
    else if (event.target.name === 'AccesLeadAPV') {
      utilisateur.AccesLeadAPV = event.target.checked;
    }
    else if (event.target.name === 'IDDRProfileEsellerLead') {
      utilisateur.IDDRProfileEsellerLead = parseInt(event.target.value, 10) ? event.target.value : null;

      if (utilisateur.IDDRProfileEsellerLead != 3) { // si n'est plus chef des ventes, il n'est plus en copie des emails eseller lead
        utilisateur.EnCopieRDVeSellerLead = false;
      }
    }
    else if (event.target.name === 'EnCopieRDVeSellerLead') {
      utilisateur.EnCopieRDVeSellerLead = event.target.checked;
    }
    else if (event.target.name === "HorsTraitementLeads") {
      utilisateur.HorsTraitementLeads = event.target.checked;
    }
    else if (event.target.name === "HorsTraitementLeadsAPV") {
      utilisateur.HorsTraitementLeadsAPV = event.target.checked;
    }

    fetch(localStorage.backoffice_api + '/api/utilisateurs/'+utilisateur.IDPeople, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(utilisateur)
    })
    .then((response) => {

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === utilisateur.IDPeople;
      });

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })

        throw Error(response);
      }

      var indexUtilisateur = lodash.findIndex(this.state.utilisateurs, (u) => {
        return u.IDPeople === utilisateur.IDPeople;
      })

      var utilisateurs = this.state.utilisateurs;
      utilisateurs[indexUtilisateur] = utilisateur;

      this.setState({
        utilisateurs: utilisateurs,
        updateInProgress: updateInProgress,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if(this.state.fetchingUtilisateurs || this.state.fetchingProfilsLeads) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="pt-4 pb-2">Chargement en cours ...</div>
          <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
        </div>
      )
    }

    return (
      <div>

        <div>
          <div className='float-left'>
            <Link to='/utilisateurs' className='btn btn-light' style={{ marginTop: '5px', marginBottom: '15px', }}>
              <i className="fa fa-list"></i> Gérer tous les utilisateurs
            </Link>
          </div>

          <div className='float-right'>
            <div className="form-inline" style={{ marginTop: '2px' }}>
              <div className="input-group">
                <span className="input-group-addon" id="basic-addon1"><i className='fa fa-filter'></i></span>
                <input type='search' className='form-control form-control-sm float-right' value={this.state.filterUtilisateursName} onChange={(event) => this.setState({filterUtilisateursName: event.target.value})} placeholder='Filtre par nom' />
              </div>
            </div>
          </div>

          <div className='clearfix'></div>
        </div>

        <div className="alert alert-primary" role="alert">
          Sont affichés seulement les utilisateurs actifs
        </div>

        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Profil eSeller Team</th>
              <th className='text-center'>Profil eSeller Lead</th>
              <th className='text-center'>TypeVO</th>
              <th className='text-center'>TypeVN</th>
              <th className='text-center'>TypeVDL</th>
              <th className='text-center'>TypeConseiller</th>
              <th className='text-center'>TypeAutre</th>
              <th className='text-center'>Accès Suivi leads APV</th>
              <th className='text-center'>Copie email RDV</th>
              <th className='text-center' style={{fontSize: '0.8em'}}>Hors traitement leads VN/VO</th>
              <th className='text-center' style={{fontSize: '0.8em'}}>Hors traitement leads APV</th>
              <th className='text-center'></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.utilisateurs.map((utilisateur) => {

              if (!utilisateur.Actif) {
                return false;
              }

              if (this.state.filterUtilisateursName) {
                if (utilisateur.Name.toLowerCase().search(this.state.filterUtilisateursName.toLowerCase()) === -1) {
                  return false;
                }
              }

              return (<tr key={utilisateur.IDPeople} className={(utilisateur.Actif && !utilisateur.TypeVO && !utilisateur.TypeVN && !utilisateur.TypeVDL) || (utilisateur.Actif && !utilisateur.DRProfileEsellerLead_Libelle) ? 'table-warning' : ''}>
                <td>{utilisateur.IDPeople}</td>
                <td>{utilisateur.Name}</td>
                <td>{utilisateur.EMail}</td>
                <td>{utilisateur.DRPROFILE_DESCR}</td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <select value={utilisateur.IDDRProfileEsellerLead ? utilisateur.IDDRProfileEsellerLead : ''} onChange={this.onChange.bind(this, utilisateur)} name="IDDRProfileEsellerLead" className="form-control form-control-sm">
                      <option value='0'></option>
                      {this.state.profilsLead.map((profil, i) => {
                        return <option key={i} value={profil.IDDRProfileEsellerLead}>{profil.Libelle}</option>
                      })}
                    </select>
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.TypeVO} name='TypeVO' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.TypeVN} name='TypeVN' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.TypeVDL} name='TypeVDL' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.TypeConseiller} name='TypeConseiller' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.TypeAutre} name='TypeAutre' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.AccesLeadAPV} name='AccesLeadAPV' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.EnCopieRDVeSellerLead} name='EnCopieRDVeSellerLead' disabled={utilisateur.IDDRProfileEsellerLead != 3} />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.HorsTraitementLeads} name='HorsTraitementLeads' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>
                  {this.state.updateInProgress.indexOf(utilisateur.IDPeople) === -1 ?
                    <input type='checkbox' onChange={this.onChange.bind(this, utilisateur)} checked={utilisateur.HorsTraitementLeadsAPV} name='HorsTraitementLeadsAPV' />
                  : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                </td>
                <td className='text-center'>{utilisateur.Devices ? <i className="fa fa-mobile" aria-hidden="true" style={{ fontSize: '29px' }}></i> : null}</td>
                <td className='text-center text-nowrap'>
                  <Link to={'/utilisateurs/'+utilisateur.IDPeople} className='btn btn-primary btn-sm'>
                    <i className="fa fa-pencil"></i>
                  </Link>
                  &nbsp;
                  <div className="dropdown" style={{ display: 'inline-block' }}>
                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Actions
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                      <Link className='dropdown-item' target='_blank' to={'/utilisateurs/'+utilisateur.IDPeople+'/connect/team'}>Se connecter sur eSeller Team</Link>
                      {this.state.configEsellerLead && this.state.configEsellerLead.Actif ? <Link className='dropdown-item' target='_blank' to={'/utilisateurs/'+utilisateur.IDPeople+'/connect/lead'}>Se connecter sur eSeller Lead</Link> : null}
                      <Link className='dropdown-item' target='_blank' to={'/utilisateurs/'+utilisateur.IDPeople+'/connect/reporting'}>Se connecter sur Reporting eSeller</Link>
                    </div>
                  </div>
                </td>
              </tr>)
          })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(Utilisateurs);
