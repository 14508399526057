import React, { useEffect, useState } from "react"
import Select from 'react-select'
import { DealershipParameters, DealershipGroup, TypeEntite } from "./Interfaces"
import { GroupementDealershipOption } from "./GroupementDealershipOption"

declare const window: any

interface Props
{
    dealerships: DealershipParameters[]
    isEditing: boolean
    selectedGroupDealerships: DealershipGroup | null
    createGroupDealerships: (dealerships: DealershipGroup) => void
    updateGroupDealerships: (dealerships: DealershipGroup) => void
    cancelEdit: () => void
}

export default function ModalCreateGroupement(props: Props) {
    const [ groupName, setGroupName ] = useState<string>("")
    const [ selectedDealerships, setSelectedDealerships ] = useState<DealershipParameters[]>([] as DealershipParameters[])
    const [ ongletActif, setOngletActif ] = useState<TypeEntite>(TypeEntite.Stock)

    const getGroupementParametersIndex = (dealershipId: number): number | null => {
        const groupement = selectedDealerships.findIndex((groupement) => groupement.value === dealershipId)
        if (groupement !== -1) {
            return groupement
        }
        return null
    }

    const updateAccessPermission = (dealership: DealershipParameters, parameterKey: keyof DealershipParameters) => {
        const groupementIndex = getGroupementParametersIndex(dealership.value)
        const dealerships = [ ...selectedDealerships ]

        if (groupementIndex !== null) {
            (dealerships[groupementIndex][parameterKey] as boolean) = !dealerships[groupementIndex][parameterKey]
            setSelectedDealerships(dealerships)
        }
    }

    const updateCustomDealerships = (dealership: DealershipParameters, parameterKey: keyof DealershipParameters, customDealerships: DealershipParameters[]) => {
        const groupementIndex = getGroupementParametersIndex(dealership.value)
        const dealerships = [ ...selectedDealerships ]

        if (groupementIndex !== null) {
            (dealerships[groupementIndex][parameterKey] as DealershipParameters[]) = customDealerships
            setSelectedDealerships(dealerships)
        }
    }

    const submit = () => {
        if (props.isEditing) {
            props.updateGroupDealerships({
                groupId: props.selectedGroupDealerships?.groupId ?? 0,
                groupName,
                dealerships: selectedDealerships,
            })
        } else {
            props.createGroupDealerships({
                groupName,
                dealerships: selectedDealerships,
            })
        }
        setSelectedDealerships([])
        setGroupName("")
    }

    const beforeClosingModal = () => {
        setSelectedDealerships([])
        setGroupName("")
        setOngletActif(TypeEntite.Stock)
        window.$('#leads-tab').removeClass('active')
        window.$('#offers-tab').removeClass('active')
        window.$('#stock-tab').addClass('active')
        props.cancelEdit()
    }

    const updateSelectedDealerships = (selDealerships: DealershipParameters[]) => {
        const selectedDealershipsTemp = selDealerships.map((dealership) => {
            const dAccessToStock = dealership.accessDealershipsStock?.filter((dAccess) => selDealerships.some((d) => d.value === dAccess.value))
            const dAccessToLeads = dealership.accessDealershipsLeads?.filter((dAccess) => selDealerships.some((d) => d.value === dAccess.value))
            const dAccessToOffers = dealership.accessDealershipsOffers?.filter((dAccess) => selDealerships.some((d) => d.value === dAccess.value))
            return {
                ...dealership,
                accessDealershipsStock: dAccessToStock,
                accessDealershipsLeads: dAccessToLeads,
                accessDealershipsOffers: dAccessToOffers,
            }
        })
        setSelectedDealerships(selectedDealershipsTemp)
    }

    useEffect(() => {
        setSelectedDealerships(props.selectedGroupDealerships?.dealerships ?? [])
        setGroupName(props.selectedGroupDealerships?.groupName ?? "")
    }, [ props.selectedGroupDealerships ])

    useEffect(() => {

        setSelectedDealerships(selectedDealerships.sort((a, b) => a.label.localeCompare(b.label)))
    }, [ selectedDealerships ])

    return (
        <div id="modalCreateGroupement" className="modal fade" tabIndex={-1} role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <h5 className="modal-title">Configuration Plaque</h5>
                        <button onClick={beforeClosingModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div style={{ gap: '15px' }} className={"d-flex flex-column w-100"}>
                            <div className={"d-flex flex-row align-items-center w-75"}>
                                <span className={"w-25"} style={{ fontSize: '.9em' }}>Nom de la Plaque</span>
                                <input type="text" onChange={(event) => setGroupName(event.currentTarget.value)}
                                       value={groupName} className={"form-control w-75"}/>
                            </div>
                            <div className={"d-flex flex-row align-items-center w-75"} style={{ zIndex: 10 }}>
                                <span className={"w-25"} style={{ fontSize: '.9em' }}>Site(s) concerné(s)</span>
                                <Select className={"w-75"} placeholder="Aucun site sélectionné" multi={true} closeOnSelect={false} options={props.dealerships} value={selectedDealerships}
                                        onChange={(selectedOptions: DealershipParameters[]) => updateSelectedDealerships(selectedOptions)}
                                />
                            </div>
                            <ul className="nav nav-tabs" id="groupementTabs">
                                <li className="nav-item">
                                    <a className="nav-link active" style={{ fontSize: '.9em' }} id="stock-tab" data-toggle="tab" href="#stock" role="tab" aria-controls="stock" aria-selected="true"
                                       onClick={() => setOngletActif(TypeEntite.Stock)}
                                    >
                                        Stock
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{ fontSize: '.9em' }} id="leads-tab" data-toggle="tab" href="#leads" role="tab" aria-controls="leads" aria-selected="true"
                                       onClick={() => setOngletActif(TypeEntite.Leads)}
                                    >
                                        Affaires
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" style={{ fontSize: '.9em' }} id="offers-tab" data-toggle="tab" href="#offers" role="tab" aria-controls="offers" aria-selected="true"
                                       onClick={() => setOngletActif(TypeEntite.Offers)}
                                    >
                                        Offres
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
                                {ongletActif === TypeEntite.Stock && (
                                    <div className="tab-pane show active" id="stock" role="tabpanel" aria-labelledby="stock-tab">
                                        {selectedDealerships.length > 0 ? (
                                            <div style={{ gap: '10px' }} className={"d-flex flex-column w-100"}>
                                                {selectedDealerships.map((option: DealershipParameters) => (
                                                    <GroupementDealershipOption
                                                        key={`${option.value}-stock`}
                                                        selectedDealerships={selectedDealerships.filter((dealership) => dealership.value !== option.value)}
                                                        dealershipListed={option}
                                                        dealershipAccessProperty={"canSeeAllStock"}
                                                        customDealershipsAccessProperty={"accessDealershipsStock"}
                                                        updateCustomDealerships={updateCustomDealerships}
                                                        updateAccessPermission={updateAccessPermission}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div>
                                                <span>Vous n'avez pas encore sélectionné de sites</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {ongletActif === TypeEntite.Leads && (
                                    <div className="tab-pane fade show" id="leads" role="tabpanel" aria-labelledby="leads-tab">
                                        {selectedDealerships.length > 0 ? (
                                            <div style={{ gap: '10px' }} className={"d-flex flex-column w-100"}>
                                                {selectedDealerships.map((option: DealershipParameters) => (
                                                    <GroupementDealershipOption
                                                        key={`${option.value}-leads`}
                                                        selectedDealerships={selectedDealerships.filter((dealership) => dealership.value !== option.value)}
                                                        dealershipListed={option}
                                                        dealershipAccessProperty={"canSeeAllLeads"}
                                                        customDealershipsAccessProperty={"accessDealershipsLeads"}
                                                        updateCustomDealerships={updateCustomDealerships}
                                                        updateAccessPermission={updateAccessPermission}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div>
                                                <span>Vous n'avez pas encore sélectionné de sites</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {ongletActif === TypeEntite.Offers && (
                                    <div className="tab-pane fade show" id="offers" role="tabpanel" aria-labelledby="offers-tab">
                                        {selectedDealerships.length > 0 ? (
                                            <div style={{ gap: '10px' }} className={"d-flex flex-column w-100"}>
                                                {selectedDealerships.map((option: DealershipParameters) => (
                                                    <GroupementDealershipOption
                                                        key={`${option.value}-offers`}
                                                        selectedDealerships={selectedDealerships.filter((dealership) => dealership.value !== option.value)}
                                                        dealershipListed={option}
                                                        dealershipAccessProperty={"canSeeAllOffers"}
                                                        customDealershipsAccessProperty={"accessDealershipsOffers"}
                                                        updateCustomDealerships={updateCustomDealerships}
                                                        updateAccessPermission={updateAccessPermission}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div>
                                                <span>Vous n'avez pas encore sélectionné de sites</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={beforeClosingModal} className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        <button type="button" onClick={submit} className="btn btn-primary" data-dismiss="modal"
                                disabled={groupName.length === 0 || selectedDealerships.length === 0}>
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
