import React from "react"
import { DealershipParameters } from "./Interfaces"
import Select from "react-select"

type AccessParameterProperty = "canSeeAllStock" | "canSeeAllLeads" | "canSeeAllOffers"
type CustomDealershipsAccessProperty = "accessDealershipsStock" | "accessDealershipsLeads" | "accessDealershipsOffers"

interface Props
{
    selectedDealerships: DealershipParameters[]
    dealershipListed: DealershipParameters
    dealershipAccessProperty: AccessParameterProperty
    customDealershipsAccessProperty: CustomDealershipsAccessProperty
    updateCustomDealerships: (dealership: DealershipParameters, parameterKey: keyof DealershipParameters, customDealerships: DealershipParameters[]) => void
    updateAccessPermission: (dealership: DealershipParameters, parameterKey: keyof DealershipParameters) => void
}

export function GroupementDealershipOption(props: Props) {
    const getLabelToDisplay = () => {
        if (props.dealershipListed[props.dealershipAccessProperty]) {
            return "Tout"
        }
        else {
            return "Aucun"
        }
    }

    return (
        <div className={"d-flex container rounded flex-row align-items-center bg-light p-2"} style={{ gap: '20px', minHeight: '3.5em' }} key={props.dealershipListed.value}>
            <span className={"col-3"}>{props.dealershipListed.label}</span>
            <div className="custom-control custom-switch col-1">
                <input style={{ height: '4em' }} type="checkbox" checked={props.dealershipListed[props.dealershipAccessProperty]} className="custom-control-input"
                       id={props.dealershipListed.value.toString()} onChange={() => props.updateAccessPermission(props.dealershipListed, props.dealershipAccessProperty)}
                />
                <label className="custom-control-label" style={{ fontSize: '.9em' }} htmlFor={props.dealershipListed.value.toString()}>{getLabelToDisplay()}</label>
            </div>
            <div className={"d-flex flex-row col-7 align-items-center flex-grow-1"} style={{ gap: '15px' }}>
                {!props.dealershipListed[props.dealershipAccessProperty] &&
                    <>
                        <span style={{ fontSize: '.9em' }}>Personnalisé</span>
                        <Select className={"w-100"} closeOnSelect={false} placeholder="Aucun site sélectionné" value={props.dealershipListed[props.customDealershipsAccessProperty]} multi={true} options={props.selectedDealerships}
                                onChange={(selectedOption: DealershipParameters[]) => props.updateCustomDealerships(props.dealershipListed, props.customDealershipsAccessProperty, selectedOption)}
                        />
                    </>
                }
            </div>
        </div>
    )
}
