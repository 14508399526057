import React, { Component } from 'react';
import ModalAddCategorieClient from './ModalAddCategorieClient';
import ModalAddCategorieProduction from './ModalAddCategorieProduction';
import ModalAddSousCategorieProduction from './ModalAddSousCategorieProduction';
import ModalAddVolumeProduction from './ModalAddVolumeProduction';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      categoriesClient : [],
      categoriesProduction : [],
      volumesProduction: [],

      edit: null,
      saving: false,
      updated: false,
    }
  }

  componentDidMount() {
    this.fetchCategoriesClient();
    this.fetchCategoriesProduction();
    this.fetchVolumesProduction();
  }

  fetchCategoriesClient() {
    fetch(localStorage.backoffice_api + '/api/categoriesClients', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      this.setState({
        categoriesClient: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchCategoriesProduction(){
    fetch(localStorage.backoffice_api + '/api/categoriesProduction', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var categoriesProduction = json; //lodash.orderBy(json, 'Libelle');

      this.setState({
        categoriesProduction: categoriesProduction,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchVolumesProduction(){
    fetch(localStorage.backoffice_api + '/api/volumesProduction', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var volumesProduction = json; //lodash.orderBy(json, 'Libelle');

      this.setState({
        volumesProduction: volumesProduction,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  edit(type, ID, value){
    if(this.state.saving)return;
    this.setState({
      updated: false,
      edit: {
        type: type,
        ID: ID,
        originalValue: value
      }
    })
  }

  cancelEdit(){
    this.onChangeValueEdit(this.state.edit.originalValue);
    this.setState({
      saving: false,
      updated: false,
      edit: null
    })
  }

  isEditing(type, ID){
    return this.state.edit && this.state.edit.type === type && this.state.edit.ID === ID;
  }

  onKeyDown(event){
    if (event.key === 'Enter') {
      this.saveEdit();
    }
  }

  onChangeValueEdit(newValue){
    if(this.state.saving)return;

    switch(this.state.edit.type){
      case 'categoriesClient':
        var categoriesClient = lodash.forEach(this.state.categoriesClient, (categ) => {
          if(categ.IDCategorieClient === this.state.edit.ID){
            categ.Libelle = newValue
            return false;
          }
        });
        this.setState({
          categoriesClient:categoriesClient,
          updated: true,
        })
      break;
      case 'categoriesProduction':
        var categoriesProduction = lodash.forEach(this.state.categoriesProduction, (categ) => {
          if(categ.IDCategorieProduction === this.state.edit.ID){
            categ.Libelle = newValue
            return false;
          }
        });
        this.setState({
          categoriesProduction:categoriesProduction,
          updated: true,
        })
      break;
      case 'sousCategoriesProduction':
        var found = false;
        var categoriesProduction = lodash.forEach(this.state.categoriesProduction, (categ) => {
          lodash.forEach(categ.sousCategoriesProduction, (sCateg) => {
            if(sCateg.IDSousCategorieProduction === this.state.edit.ID){
              sCateg.Libelle = newValue
              found = true
              return false
            }
            if(found)
              return false;
          })
        });
        this.setState({
          categoriesProduction:categoriesProduction,
          updated: true,
        })
      break;
      case 'volumesProduction':
        var volumesProduction = lodash.forEach(this.state.volumesProduction, (vol) => {
          if(vol.IDVolumeProduction === this.state.edit.ID){
            vol.Libelle = newValue
            return false;
          }
        });
        this.setState({
          volumesProduction:volumesProduction,
          updated: true,
        })
      break;
    }
  }

  saveEdit(){
    if(this.state.updated)
    {
      this.setState({
        saving: true
      })

      var url = localStorage.backoffice_api + '/api/'+this.state.edit.type+'/'+this.state.edit.ID;
      switch(this.state.edit.type){
        case 'categoriesClient':
          var categorieClient = lodash.find(this.state.categoriesClient, (categ) => {
            return categ.IDCategorieClient === this.state.edit.ID;
          });
          if(categorieClient.Libelle === ""){
            alert("Le libellé ne peut pas être vide");
            this.cancelEdit();
            return false;
          }
          var body = {
            libelle: categorieClient.Libelle
          }
        break;
        case 'categoriesProduction':
          var categorieProduction = lodash.find(this.state.categoriesProduction, (categ) => {
            return categ.IDCategorieProduction === this.state.edit.ID;
          });
          if(categorieProduction.Libelle === ""){
            alert("Le libellé ne peut pas être vide");
            this.cancelEdit();
            return false;
          }
          var body = {
            libelle: categorieProduction.Libelle
          }
        break;
        case 'sousCategoriesProduction':
          var sousCategoriesProduction;
          var categorieProduction = lodash.forEach(this.state.categoriesProduction, (categ) => {
            sousCategoriesProduction = lodash.find(categ.sousCategoriesProduction, (sCateg) => {
              return sCateg.IDSousCategorieProduction === this.state.edit.ID;
            });
            if(sousCategoriesProduction)
              return false;
          });
          if(sousCategoriesProduction.Libelle === ""){
            alert("Le libellé ne peut pas être vide");
            this.cancelEdit();
            return false;
          }
          var body = {
            libelle: sousCategoriesProduction.Libelle
          }
        break;
        case 'volumesProduction':
          var volumeProduction = lodash.find(this.state.volumesProduction, (vol) => {
            return vol.IDVolumeProduction === this.state.edit.ID;
          });
          if(volumeProduction.Libelle === ""){
            alert("Le libellé ne peut pas être vide");
            this.cancelEdit();
            return false;
          }
          var body = {
            libelle: volumeProduction.Libelle
          }
        break;
      }

      fetch(url, {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(body)
      })
      .then((response) => {

          if (!response.ok) {
            response.json().then((value) => {
              alert(value.message)
            });

            this.setState({
              saving: false,
            })

            throw Error(response);
          }

          this.setState({
            saving: false,
            edit: null,
          })
      })
      .catch((error) => {
          console.log(error)
      });
    }
    else {
      this.setState({
        saving: false,
        edit: null,
      })
    }
  }


  render() {

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col">

            <h2>Configuration globale Agri</h2>

            <br/>

            <span style={{fontSize: '0.9em'}} className='font-italic'>Double-clic sur une valeur pour la modifier</span>
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="card mr-1">
                  <div className="card-header p-1 align-middle">
                    <div className="row">
                      <div className="col-5 d-flex align-items-center font-weight-bold pl-4">
                        Catégories clients
                      </div>
                      <div className="col-7 text-right">

                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {this.state.categoriesClient.length ?
                      <table className="table table-striped table-sm table-hover mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Libellé</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.categoriesClient.map((categ) => {
                          return <tr key={categ.IDCategorieClient}><td>{categ.IDCategorieClient}</td><td onDoubleClick={() => this.edit('categoriesClient', categ.IDCategorieClient, categ.Libelle)}>
                            {this.isEditing('categoriesClient', categ.IDCategorieClient) ?
                              <span>
                                <input type='text' className='form-control w-75 d-inline' value={categ.Libelle} onKeyDown={(event) => this.onKeyDown(event)} onChange={(e) => this.onChangeValueEdit(e.target.value)} onBlur={(e) => this.saveEdit(e.target.value)}/>
                                {this.state.saving ?
                                  <i className='fa fa-spinner fa-spin fa-fw'></i>
                                  :null
                                }
                              </span>
                            :
                              categ.Libelle
                            }
                          </td></tr>
                        })}
                        </tbody>
                      </table>
                      :null
                    }
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-12">
                <div className="card mr-1">
                  <div className="card-header p-1 align-middle">
                    <div className="row">
                      <div className="col-5 d-flex align-items-center font-weight-bold pl-4">
                        Catégories de production
                      </div>
                      <div className="col-7 text-right">
                        <button type='button' className='btn btn-light btn-sm' data-toggle="modal" data-target="#modalAddCategorieProduction" onClick={() => this.refs.modalAddCategorieProduction.reset()}>
                          <i className='fa fa-plus'></i> Ajouter catégorie
                        </button>
                        <button type='button' className='btn btn-light btn-sm' data-toggle="modal" data-target="#modalAddSousCategorieProduction" onClick={() => this.refs.modalAddSousCategorieProduction.reset()}>
                          <i className='fa fa-plus'></i> Ajouter sous-catégorie
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {this.state.categoriesProduction.length ?
                      <table className="table table-striped table-sm table-hover mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Libellé</th>
                            <th>Sous-catégories</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.categoriesProduction.map((categ) => {
                          return <tr key={categ.IDCategorieProduction}><td>{categ.IDCategorieProduction}</td><td onDoubleClick={() => this.edit('categoriesProduction', categ.IDCategorieProduction, categ.Libelle)}>
                            {this.isEditing('categoriesProduction', categ.IDCategorieProduction) ?
                              <span>
                                <input type='text' className='form-control w-75 d-inline' value={categ.Libelle} onKeyDown={(event) => this.onKeyDown(event)} onChange={(e) => this.onChangeValueEdit(e.target.value)} onBlur={(e) => this.saveEdit(e.target.value)}/>
                                {this.state.saving ?
                                  <i className='fa fa-spinner fa-spin fa-fw'></i>
                                  :null
                                }
                              </span>
                            :
                              categ.Libelle
                            }
                          </td>
                          <td>
                            {categ.sousCategoriesProduction.map((sousCateg) => {
                              return (<div className="row border-left border-bottom" key={sousCateg.IDSousCategorieProduction}>
                                <div className="col-auto" onDoubleClick={() => this.edit('sousCategoriesProduction', sousCateg.IDSousCategorieProduction, sousCateg.Libelle)}>
                                {this.isEditing('sousCategoriesProduction', sousCateg.IDSousCategorieProduction) ?
                                  <span>
                                    <input type='text' className='form-control w-75 d-inline' value={sousCateg.Libelle} onKeyDown={(event) => this.onKeyDown(event)} onChange={(e) => this.onChangeValueEdit(e.target.value)} onBlur={(e) => this.saveEdit(e.target.value)}/>
                                    {this.state.saving ?
                                      <i className='fa fa-spinner fa-spin fa-fw'></i>
                                      :null
                                    }
                                  </span>
                                :
                                  sousCateg.Libelle
                                }
                                </div>
                              </div>)
                            })}
                          </td>
                        </tr>
                        })}
                        </tbody>
                      </table>
                      :null
                    }
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-12">
                <div className="card mr-1">
                  <div className="card-header p-1 align-middle">
                    <div className="row">
                      <div className="col-5 d-flex align-items-center font-weight-bold pl-4">
                        Volumes de production
                      </div>
                      <div className="col-7 text-right">
                        <button type='button' className='btn btn-light btn-sm' data-toggle="modal" data-target="#modalAddVolumeProduction" onClick={() => this.refs.modalAddVolumeProduction.reset()}>
                          <i className='fa fa-plus'></i> Ajouter volume
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {this.state.volumesProduction.length ?
                      <table className="table table-striped table-sm table-hover mb-0">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Libellé</th>
                            <th>Type entier</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.volumesProduction.map((volume) => {
                          return (
                            <tr key={volume.IDVolumeProduction}>
                              <td>{volume.IDVolumeProduction}</td>
                              <td onDoubleClick={() => this.edit('volumesProduction', volume.IDVolumeProduction, volume.Libelle)}>
                                {this.isEditing('volumesProduction', volume.IDVolumeProduction) ?
                                  <span>
                                    <input type='text' className='form-control w-75 d-inline' value={volume.Libelle} onKeyDown={(event) => this.onKeyDown(event)} onChange={(e) => this.onChangeValueEdit(e.target.value)} onBlur={(e) => this.saveEdit(e.target.value)}/>
                                    {this.state.saving ?
                                      <i className='fa fa-spinner fa-spin fa-fw'></i>
                                      :null
                                    }
                                  </span>
                                :
                                  volume.Libelle
                                }
                              </td>
                              <td>{volume.EstNombreEntier ? 'Oui' : 'Non'}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                      :null
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <ModalAddCategorieClient ref="modalAddCategorieClient" onSubmit={() => this.fetchCategoriesClient()}/>
        <ModalAddCategorieProduction ref="modalAddCategorieProduction" onSubmit={() => this.fetchCategoriesProduction()}/>
        <ModalAddSousCategorieProduction ref="modalAddSousCategorieProduction" categoriesProduction={this.state.categoriesProduction} onSubmit={() => this.fetchCategoriesProduction()}/>
        <ModalAddVolumeProduction ref="modalAddVolumeProduction" onSubmit={() => this.fetchVolumesProduction()}/>
      </div>
    )
  }
}

export default Index;
