import React, { Component } from 'react';
import ModalDetailsLog from './ModalDetailsLog';
import moment from 'moment'
import 'moment/locale/fr';
var lodash = require('lodash')

class Logs extends Component {

  constructor(props) {
    super(props);
    moment.locale('fr');

    this.state = {
        logs : [],
        fetchingLogs: false,
        numPage: 1,
        nbPages: 0,
        showLog: null,

        actions: [],
        filters:{
          user: "",
          action: "",
          request: "",
          response: "",
          date: ""
        },
    }

    this.fetchSetTimeout = null;
  }

  componentDidMount() {
    this.fetchLogs();
  }

  fetchLogs(){
    this.setState({
      fetchingLogs: true
    })
    var url = localStorage.backoffice_api + '/api/interfaces/planetVO2/logs?numPage='+this.state.numPage;
    lodash.forEach(this.state.filters, (value, key) => {
      if(value != "")
        url += "&filter_"+key+"="+value;
    })

    fetch(url, {
      method : 'GET',
      headers : {
        authorization : localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        logs : json.logs,
        nbPages: json.nbPages,
        fetchingLogs: false,
        actions: json.actions
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changePage(page){
    this.setState({
      numPage: page
    }, () => this.fetchLogs())

    return false;
  }

  showLogDetails(log){
    this.setState({
      showLog: log
    })
  }

  onChangeFilter(event){
    if(this.fetchSetTimeout)
      clearTimeout(this.fetchSetTimeout);

    var filters = this.state.filters;
    filters[event.target.name] = event.target.value;

    this.setState({
      filters: filters,
      numPage: 1
    }, () => {
      this.fetchSetTimeout = setTimeout(this.fetchLogs.bind(this), 1000);
    })
  }

  render() {

    if(this.state.fetchingLogs){
        return <div className='container-fluid text-center'><i className="fa fa-refresh fa-spin fa-2x"></i></div>
    }

    return (
      <div className='container-fluid mb-4'>
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th>Date<input type="date" name="date" className='form-control form-control-sm' onChange={this.onChangeFilter.bind(this)} value={this.state.filters.date}/></th>
              <th>Utilisateur<input type='text' name='user' className='form-control form-control-sm' onChange={this.onChangeFilter.bind(this)} value={this.state.filters.user}/></th>
              <th>Action
                <select name='action' className='form-control form-control-sm' onChange={this.onChangeFilter.bind(this)} value={this.state.filters.action}>
                  <option value=""></option>
                  {this.state.actions.map(a => {return (<option key={a.Action} value={a.Action}>{a.Action}</option>)})}
                </select>  
              </th>
              <th>Requête<input type='text' name='request' className='form-control form-control-sm' onChange={this.onChangeFilter.bind(this)} value={this.state.filters.request}/></th>
              <th>Réponse<input type='text' name='response' className='form-control form-control-sm' onChange={this.onChangeFilter.bind(this)} value={this.state.filters.response}/></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.logs.map((log,index)=>{
              return (
                <tr key={index}>
                  <td>{moment(log.Datetime.replace("Z", "")).format("DD-MM-YYYY HH:mm:ss")}</td>
                  <td>{log.PeopleName}</td>
                  <td>{log.Action}</td>
                  <td className="text-truncate">{log.Request?.substring(0,50)}...</td>
                  <td className="text-truncate">{log.Response?.substring(0,50)}...</td>
                  <td className="text-right">
                    <a href="#" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalDetailsLog" onClick={this.showLogDetails.bind(this, log)}>Détails</a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <nav>
          <ul className="pagination justify-content-center">
            <li className={"page-item"+(this.state.numPage == 1 ? " disabled" : "")}>
              <a className="page-link" href="#" onClick={() => this.changePage(this.state.numPage-1)}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {
              lodash.map(lodash.range(this.state.numPage - 10, this.state.numPage + 10), (n) => {
                if(n < 1 || n > this.state.nbPages)
                  return null;
                
                return <li key={n} className={"page-item"+(this.state.numPage == n ? " active" : "")}><a className="page-link" href="#" onClick={() => this.changePage(n)}>{n}</a></li>
              })
            }
            <li className={"page-item"+(this.state.numPage == this.state.nbPages ? " disabled" : "")}>
              <a className="page-link" href="#" onClick={() => this.changePage(this.state.numPage+1)}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>

        <ModalDetailsLog log={this.state.showLog} ref="modalDetailsLog"/>
      </div>);
  }
}

export default Logs;
