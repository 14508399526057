import { Annonceur, PublicationSubscription } from "./PublicationUtilsEnums";


interface Props {
    setSubscription: (subscription: PublicationSubscription) => void
    subscription: PublicationSubscription
    advertisers: Annonceur[]
    createSubscription: (e: any) => void
    selectedActivityId: number | undefined
}

export default function ModalCreationAbonnement(props: Props) {

    return (
        <div data-backdrop="static" className="modal fade" id="createModal" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
            <form onSubmit={props.createSubscription} id="createSubscription">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createModalLabel">Ajouter un abonnement</h5>
                            <button onClick={() => props.setSubscription({ ...props.subscription, Identifiant: "", IDAnnonceur: 0, NbVehiculesMax: 0 })} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select
                                required
                                value={props.subscription.IDAnnonceur ? props.subscription.IDAnnonceur : ''}
                                onChange={(e) => props.setSubscription({ ...props.subscription, IDAnnonceur: Number(e.currentTarget.value) })}
                                id='IDAnnonceur'
                                name="IDAnnonceur"
                                className="form-control form-control-sm"
                            >
                                <option value="">Choisir un annonceur</option>
                                {props.advertisers.filter((a) => (a.IDActivite === props.selectedActivityId || a.IDActivite === null)).map((a, i) => {
                                    return <option key={a.IDAnnonceur} value={a.IDAnnonceur}>{a.Nom}</option>
                                })}
                            </select>
                            <div className="  py-2">
                                <span>Identifiant</span>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="Identifiant"
                                    id="Identifiant"
                                    value={props.subscription.Identifiant}
                                    required
                                    onChange={(e) => props.setSubscription({ ...props.subscription, Identifiant: e.currentTarget.value })}
                                />
                            </div>
                            <div className="  py-2">
                                <span>Nb véhicules max</span>
                                <input
                                    className="form-control form-control-sm"
                                    type="number"
                                    name="TelTraceur"
                                    id="TelTraceur"
                                    value={props.subscription.NbVehiculesMax}
                                    required
                                    onChange={(e) => props.setSubscription({ ...props.subscription, NbVehiculesMax: Number(e.currentTarget.value) })}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="closeButtonCreateSubscriptionModal" onClick={() => props.setSubscription({ ...props.subscription, Identifiant: "", IDAnnonceur: 0, NbVehiculesMax: 0 })} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <input value="Sauvegarder" type="submit" className="btn btn-primary" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
