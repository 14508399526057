import React, { useEffect, useMemo, useState } from 'react'
import BackOfficeAPIClient from '../../clients/BackofficeAPIClient'
import Select from "react-select"
import { useHistory, useParams } from "react-router-dom"
import { TemplateBDC } from "./Models/TemplateBDC"
import lodash from "lodash"

type Marque = {
    value: string
    label: string
}

type Activite = {
    IDActivite: number
    Descr: string
}
export default function TemplateBDCForm() {

    const backofficeApiClient = new BackOfficeAPIClient()

    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ fetching, setFetching ] = useState<boolean>(true)
    const [ error, setError ] = useState<string>()
    const [ marques, setMarques ] = useState<Marque[]>([])
    const [ activites, setActivites ] = useState<Activite[]>([])
    const [ template, setTemplate ] = useState<TemplateBDC>({
        IDActivite: 1,
        IDMatType: null,
        Nom: "",
        DocumentBDC: "",
        DocumentCGV: "",
        Marques: [],
        FileBinary: "",
    })

    const [ isPDFFile, setIsPDFFile ] = useState(false)
    const [ templatesImpressionCGVList, setTemplatesImpressionCGVList ] = useState([])

    const { id: IDTemplate } = useParams<{ id?: string }>()
    const history = useHistory()

    useEffect(
        () => {
            if (IDTemplate) {
                fetchTemplate(Number(IDTemplate))
            } else {
                setFetching(false)
            }

            fetchActivites()
        },
        [],
    )

    useEffect(() => {
        if (IDTemplate && template.IDTemplateBDC) {
            if (template.DocumentCGV.includes("eseller-document-")) {
                setIsPDFFile(false)
            } else {
                if (template.DocumentCGV !== "" || template.DocumentCGV.includes(".pdf")) {
                    setIsPDFFile(true)
                }
            }
        }
    }, [ IDTemplate, template.IDTemplateBDC ])

    useEffect(
        () => {
            if (template && template.IDActivite) {
                fetchMarques()
            }
        },
        [ template?.IDActivite ],
    )
    useEffect(() => {
        fetchTemplatesCGV()
    }, [])

    const fetchTemplate = async (id: number) => {
        try {
            setTemplate(await backofficeApiClient.get<TemplateBDC>(`/templatesBDC/${id}`))
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setFetching(false)
        }
    }

    const fetchActivites = async () => {
        try {
            setActivites(await backofficeApiClient.get<Activite[]>("/activites"))
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        }
    }

    const fetchMarques = async () => {
        try {
            setMarques(await backofficeApiClient.get<Marque[]>(`/marques?IDActivite=${template?.IDActivite}`))
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        }
    }

    const onSelectMarques = (items: Marque[]) => {
        const marques: string[] = []
        items.forEach((option) => {
            marques.push(option.value)
        })

        setTemplate({ ...template, Marques: marques })
    }

    const onSubmit = (event: any) => {
        event.preventDefault()
        setIsLoading(true)
        if (IDTemplate) {
            updateTemplate()
        } else {
            createTemplate()
        }
    }

    const createTemplate = async () => {
        try {
            await backofficeApiClient.post(
                "/templatesBDC",
                template,
            )
            history.push('/templatesImpression?activeTab=configurationTemplateBDC')
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        }
    }

    const updateTemplate = async () => {
        try {
            await backofficeApiClient.put(
                `/templatesBDC/${template.IDTemplateBDC}`,
                template,
            )
            history.push('/templatesImpression?activeTab=configurationTemplateBDC')
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        }
    }

    const fetchTemplatesCGV = async () => {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/templatesImpression/cgv', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                },
            })
            let templatesImpressionCGVList = (await response.json())

            templatesImpressionCGVList = lodash.sortBy(templatesImpressionCGVList, (t) => {
                return t.Nom
            })

            setTemplatesImpressionCGVList(templatesImpressionCGVList)

        } catch (error) {
            console.log(error)
        }
    }

    const onSelectCGV = (event: any) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            setTemplate({
                ...template,
                FileBinary: reader.result,
                DocumentCGV: event.target.files[0].name,
            })
        }
        reader.readAsDataURL(event.target.files[0])
    }

    const fileAlreadyExist = useMemo(() => {
        return templatesImpressionCGVList.some((t: any) => t.Document === template.DocumentCGV)
    }, [ template, templatesImpressionCGVList ])

    return (
        <div className="container-fluid mb-2">
            <div className="row">
                <div className="col">
                    {fetching ?
                        <div className="d-flex flex-col align-items-center justify-content-center">
                            <i className="fa fa-spinner fa-spin fa-fw fa-2x"></i>
                        </div>
                        : error ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className="fa fa-warning"></i>
                                {error}
                            </div>
                            :
                            <>
                                <h5>Configuration des BDC par marque</h5>
                                <form className={"mt-4"} onSubmit={onSubmit}>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group row">
                                                <label htmlFor="drprofile_id" className="col-sm-3 col-form-label">Activité</label>
                                                <div className="col-sm-6">
                                                    <select id="drprofile_id"
                                                            name="drprofile_id" className="form-control form-control-sm"
                                                            value={template.IDActivite}
                                                            onChange={(e) => setTemplate({ ...template, IDActivite: Number(e.currentTarget.value), Marques: [] })}>
                                                        {activites.map((activite: Activite, i: number) => {
                                                            return <option key={i} value={activite.IDActivite}>{activite.Descr}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="form-group row">
                                                <label htmlFor="Lastname" className="col-sm-3 col-form-label">Nom</label>
                                                <div className="col-sm-6">
                                                    <input required type="text" className="form-control form-control-sm" id="Lastname" name="Lastname" value={template.Nom}
                                                           onChange={(e) => setTemplate({ ...template, Nom: e.currentTarget.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group row">
                                                <label htmlFor="Lastname" className="col-sm-3 col-form-label">Marques</label>
                                                <div className="col-sm-6">
                                                    <Select multi
                                                            onChange={(values: Marque[]) => onSelectMarques(values)}
                                                            value={template.Marques}
                                                            options={marques}
                                                            searchable
                                                            placeholder="Choisir une ou plusieurs marques"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="row">
                                                <label htmlFor="Lastname" className="col-sm-3 col-form-label">Neuf/Occasion</label>
                                                <div className="col">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input className="form-check-input" type="checkbox" name="TypeVO" checked={template.IDMatType === 1}
                                                                   onChange={() => setTemplate({ ...template, IDMatType: 1 })}/>
                                                            &nbsp; Neuf
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input className="form-check-input" type="checkbox" name="TypeVO" checked={template.IDMatType === 2}
                                                                   onChange={() => setTemplate({ ...template, IDMatType: 2 })}/>
                                                            &nbsp; Occasion
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input className="form-check-input" type="checkbox" name="TypeVO" checked={template.IDMatType === null}
                                                                   onChange={() => setTemplate({ ...template, IDMatType: null })}/>
                                                            &nbsp; Neuf & Occasion
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group row">
                                                <label htmlFor="Lastname" className="col-sm-3 col-form-label">Document BDC</label>
                                                <div className="col-sm-6">
                                                    <input type="text" className="form-control form-control-sm" id="Lastname" name="Lastname" value={template.DocumentBDC}
                                                           onChange={(e) => setTemplate({ ...template, DocumentBDC: e.currentTarget.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="form-group row">
                                                <label htmlFor="Lastname" className="col-sm-3 col-form-label">Document CGV</label>
                                                <div className="col-sm-6">
                                                    <input disabled={!!template.FileBinary} type="text" className="form-control form-control-sm" id="Lastname" name="Lastname"
                                                           value={template.DocumentCGV}
                                                           onChange={(e) => setTemplate({ ...template, DocumentCGV: e.currentTarget.value })}
                                                    />
                                                    {fileAlreadyExist && <div className="alert alert-danger" role="alert">Un document avec ce nom existe déjà</div>}
                                                    <div className="col">
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input" type="checkbox" name="TypeVO" checked={isPDFFile}
                                                                       onChange={() => setIsPDFFile(!isPDFFile)}/>
                                                                &nbsp; Fichier PDF
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isPDFFile &&
                                                        <input onChange={(e) => onSelectCGV(e)} accept=".pdf" type="file" className="form-control" id="CGVBinary" name="CGVBinary"/>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row" style={{ marginTop: '15px', marginBottom: '15px', marginLeft: "10px", marginRight: "10px" }}>
                                        <div className="col text-center">
                                            <button disabled={isLoading || fileAlreadyExist} type="submit" className="btn btn-primary">
                                                {isLoading ?
                                                    <span><i className="fa fa-spinner fa-spin fa-fw"></i>&nbsp;</span>
                                                    : null}
                                                {IDTemplate ? "Modifier" : "Enregistrer"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}