import React from "react"

interface Props
{
    active: boolean
    description?: string
}
export default function FetchProgress(props: Props) {
    if(!props.active) return <></>

    return <div className="loader-container " style={{zIndex:1}}>

        <div className={"d-flex flex-column align-items-center "}>
            <i className="fas fa-spinner loader"></i>

            <div className={"mt-3"} style={{fontSize:"1.3rem"}}>
                {props.description}
            </div>
        </div>
    </div>

}