import React from "react"

interface Props
{
    title: string
}
export default function HeaderPageWally(props:Props) {
    return <div className={"col"}>
        <h2 className={"mb-4 d-flex justify-content-between"}>
            <div>
                {props.title}
            </div>
            <div style={{ fontSize: "0.6em" }} className={"text-secondary"}>
               {/* Actif de 7h à 20h du Lundi au samedi*/}
            </div>
        </h2>
    </div>

}
