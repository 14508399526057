export enum TypeEntite
{
    Stock = 1,
    Leads = 2,
    Offers = 3
}

export interface DealershipResponseAPIData
{
    IDSite: number
    Descr: string
    GroupementSiteAccessStock: boolean,
    GroupementSiteAccessLeads: boolean,
    GroupementSiteAccessOffres: boolean,
    IDGroupementSite: number,
    NomGroupementSite: string
    IDSiteAccessible: number,
    NomSiteAccessible: string
    IDTypeEntite: number,
    NomTypeEntite: TypeEntite
}

export interface DealershipAccessData
{
    IDSite: number
    GroupementSiteAccessStock: boolean,
    GroupementSiteAccessLeads: boolean,
    GroupementSiteAccessOffres: boolean,
    SitesAccessibles: {
        IDSiteAccessible: number,
        IDTypeEntite: TypeEntite
    }[]
}

export interface DealershipsGroupData
{
    IDGroupementSite?: number
    Nom: string
    Sites: DealershipAccessData[]
}

export interface DealershipGroup
{
    groupId?: number
    groupName: string
    dealerships: DealershipParameters[]
}

export interface DealershipParameters
{
    label: string
    value: number
    canSeeAllStock?: boolean
    canSeeAllLeads?: boolean
    canSeeAllOffers?: boolean
    accessDealershipsStock?: { value: number, label: string }[]
    accessDealershipsLeads?: { value: number, label: string }[]
    accessDealershipsOffers?: { value: number, label: string }[]
}
