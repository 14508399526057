import ModalCreateGroupement from "./ModalCreateGroupement"
import React, { useEffect, useState } from "react"
import { orderBy } from "lodash"
import BackOfficeAPIClient from "../../clients/BackofficeAPIClient"
import { ModalDeleteGroupement } from "./ModalDeleteGroupement"
import { DealershipResponseAPIData, DealershipParameters, DealershipGroup, DealershipsGroupData, TypeEntite } from "./Interfaces"

declare const window: any

export default function List() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [ dealerships, setDealerships ] = useState<DealershipParameters[]>([])
    const [ selectedGroupDealerships, setSelectedGroupDealerships ] = useState<DealershipGroup | null>(null)
    const [ groupsDealerships, setGroupsDealerships ] = useState<DealershipGroup[]>([])
    const [ groupsDealershipsToDelete, setGroupsDealershipsToDelete ] = useState<DealershipGroup | null>(null)
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ isEditing, setIsEditing ] = useState<boolean>(false)
    const [ collapsedStates, setCollapsedStates ] = useState<Record<number, boolean>>({})

    const toggleCollapse = (groupId: number) => {
        const collapseElement = document.getElementById(`collapse-${groupId}`)
        if (collapseElement) {
            if (collapseElement.classList.contains("show")) {
                window.$(collapseElement).collapse("hide")
            } else {
                window.$(collapseElement).collapse("show")
            }
        }
    }

    const isCollapsed = (groupId: number) => {
        return collapsedStates[groupId] ?? true
    }

    const fetchDealerships = async () => {
        try {
            return orderBy(await backofficeApiClient.get<any[]>("/sites"), "Descr")
        } catch {
            throw new Error("Erreur lors de la récupération de la configuration des sites")
        }
    }

    const fetchGroupDealerships = async () => {
        try {
            return await backofficeApiClient.get<DealershipResponseAPIData[]>("/groupementDealerships")
        } catch {
            throw new Error("Erreur lors de la récupération des groupements")
        }
    }

    const filterDealerships = (dealerships: DealershipResponseAPIData[], groupsDealerships: DealershipGroup[]) => {
        return dealerships.filter(dealership =>
            !groupsDealerships.some(groupDealerships =>
                groupDealerships.dealerships.some(groupDealership =>
                    dealership.IDSite === groupDealership.value,
                ),
            ),
        )
    }

    const formatDealerships = (dealerships: DealershipResponseAPIData[] | DealershipResponseAPIData): DealershipParameters[] => {

        const formatDealership = (dealership: DealershipResponseAPIData): DealershipParameters => {
            return {
                value: dealership.IDSite,
                label: dealership.Descr,
                canSeeAllStock: dealership.GroupementSiteAccessStock ?? false,
                canSeeAllLeads: dealership.GroupementSiteAccessLeads ?? false,
                canSeeAllOffers: dealership.GroupementSiteAccessOffres ?? false,
                accessDealershipsStock: (dealership.IDSiteAccessible && dealership.IDTypeEntite === TypeEntite.Stock) ?
                    [ { value: dealership.IDSiteAccessible, label: dealership.NomSiteAccessible } ] : [],
                accessDealershipsLeads: (dealership.IDSiteAccessible && dealership.IDTypeEntite === TypeEntite.Leads) ?
                    [ { value: dealership.IDSiteAccessible, label: dealership.NomSiteAccessible } ] : [],
                accessDealershipsOffers: (dealership.IDSiteAccessible && dealership.IDTypeEntite === TypeEntite.Offers) ?
                    [ { value: dealership.IDSiteAccessible, label: dealership.NomSiteAccessible } ] : [],
            }
        }

        return Array.isArray(dealerships) ? dealerships.map(formatDealership) : [ formatDealership(dealerships) ]
    }

    const formatGroupDealerships = (dealerships: DealershipResponseAPIData[]): DealershipGroup[] => {
        const groupedDealerships: { [key: number]: DealershipGroup } = {}

        dealerships.forEach(dealership => {
            if (!groupedDealerships[dealership.IDGroupementSite]) {
                groupedDealerships[dealership.IDGroupementSite] = {
                    groupId: dealership.IDGroupementSite,
                    groupName: dealership.NomGroupementSite,
                    dealerships: [],
                }
            }

            const group = groupedDealerships[dealership.IDGroupementSite]
            const existingDealership = group.dealerships.find(d => d.value === dealership.IDSite)

            if (existingDealership) {
                if (dealership.IDTypeEntite === TypeEntite.Stock) {
                    existingDealership.accessDealershipsStock?.push({ value: dealership.IDSiteAccessible, label: dealership.NomSiteAccessible })
                } else if (dealership.IDTypeEntite === TypeEntite.Leads) {
                    existingDealership.accessDealershipsLeads?.push({ value: dealership.IDSiteAccessible, label: dealership.NomSiteAccessible })
                } else if (dealership.IDTypeEntite === TypeEntite.Offers) {
                    existingDealership.accessDealershipsOffers?.push({ value: dealership.IDSiteAccessible, label: dealership.NomSiteAccessible })
                }
            } else {
                const formattedDealership = formatDealerships(dealership)[0]
                group.dealerships.push(formattedDealership)
            }
        })

        return Object.values(groupedDealerships).map(group => ({
            ...group,
            dealerships: orderBy(group.dealerships, "label"),
        })).sort((a, b) => a.groupName.localeCompare(b.groupName))
    }

    const getBadgeToShow = (
        dealershipAccessParam: boolean, dealership: DealershipParameters,
        type: "accessDealershipsStock" | "accessDealershipsLeads" | "accessDealershipsOffers",
    ) => {
        if (dealershipAccessParam) {
            return <span className={"badge badge-success"}>Tout</span>
        } else if (!dealershipAccessParam) {
            if (dealership[type]?.length) {
                const tooltipMessage = dealership[type]?.map((dealership) => dealership.label).join(",\n")
                return <span data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-html="true" title={tooltipMessage}
                             className={"badge badge-info"} style={{ whiteSpace: 'pre-line' }}
                >
                Personnalisé
            </span>
            } else {
                return <span className={"badge badge-warning"}>Aucun</span>
            }
        }
    }

    const mapDealershipsParameterWithApi = (groupDealerships: DealershipGroup): DealershipsGroupData => {
        return {
            IDGroupementSite: groupDealerships.groupId ?? 0,
            Nom: groupDealerships.groupName,
            Sites: groupDealerships.dealerships.map((dealership) => {
                return {
                    IDSite: dealership.value,
                    GroupementSiteAccessStock: dealership.canSeeAllStock ?? false,
                    GroupementSiteAccessLeads: dealership.canSeeAllLeads ?? false,
                    GroupementSiteAccessOffres: dealership.canSeeAllOffers ?? false,
                    SitesAccessibles: [
                        ...(dealership.canSeeAllStock
                            ? []
                            : (dealership.accessDealershipsStock ?? []).map((dealership) => ({
                                IDSiteAccessible: dealership.value,
                                IDTypeEntite: TypeEntite.Stock,
                            }))),
                        ...(dealership.canSeeAllLeads
                            ? []
                            : (dealership.accessDealershipsLeads ?? []).map((dealership) => ({
                                IDSiteAccessible: dealership.value,
                                IDTypeEntite: TypeEntite.Leads,
                            }))),
                        ...(dealership.canSeeAllOffers
                            ? []
                            : (dealership.accessDealershipsOffers ?? []).map((dealership) => ({
                                IDSiteAccessible: dealership.value,
                                IDTypeEntite: TypeEntite.Offers,
                            }))),
                    ],
                }
            }),
        }
    }

    const createGroupDealerships = async (groupDealerships: DealershipGroup) => {
        setIsLoading(true)
        const mappedGroup = mapDealershipsParameterWithApi(groupDealerships)
        await backofficeApiClient.post("/groupementDealerships", mappedGroup).then(() => fetchData())
    }

    const updateGroupDealerships = async (groupDealerships: DealershipGroup) => {
        setIsLoading(true)
        const mappedGroup = mapDealershipsParameterWithApi(groupDealerships)
        await backofficeApiClient.put(`/groupementDealerships/${mappedGroup.IDGroupementSite}`, mappedGroup).then(() => fetchData())
    }

    const deleteGroupDealerships = async () => {
        if (groupsDealershipsToDelete) {
            setIsLoading(true)
            const mappedGroup = mapDealershipsParameterWithApi(groupsDealershipsToDelete)
            await backofficeApiClient.delete(`/groupementDealerships/${groupsDealershipsToDelete.groupId}`, mappedGroup).then(() => {
                setGroupsDealershipsToDelete(null)
                fetchData()
            })
        }
    }

    const openEditModal = (groupDealerships: DealershipGroup) => {
        setSelectedGroupDealerships(groupDealerships)
        setIsEditing(true)
        window.$('#modalCreateGroupement').modal('show')
    }

    const openCreateModal = () => {
        setSelectedGroupDealerships(null)
        setIsEditing(false)
    }

    const openConfirmationToDeleteModal = (groupDealerships: DealershipGroup) => {
        setGroupsDealershipsToDelete(groupDealerships)
        window.$('#modalDeleteGroupement').modal('show')
    }

    const fetchData = async () => {
        const apiDealerships = await fetchDealerships()
        const apiGroupDealerships = await fetchGroupDealerships()

        const formattedGroupDealerships = formatGroupDealerships(apiGroupDealerships)
        const filteredDealerships = filterDealerships(apiDealerships, formattedGroupDealerships)
        const formattedDealerships = formatDealerships(filteredDealerships)

        setSelectedGroupDealerships(null)
        setDealerships(formattedDealerships)
        setGroupsDealerships(formattedGroupDealerships)

        setIsLoading(false)
    }

    const listenCollapseEvents = () => {
        const handleCollapseToggle = (event: Event, isShowing: boolean) => {
            const targetId = event.target && (event.target as HTMLElement).id
            if (targetId) {
                const groupIdMatch = targetId.match(/collapse-(\d+)/)
                if (groupIdMatch) {
                    const groupId = parseInt(groupIdMatch[1], 10)
                    setCollapsedStates((prevState) => ({
                        ...prevState,
                        [groupId]: !isShowing,
                    }))
                }
            }
        }

        const handleShow = (event: Event) => handleCollapseToggle(event, true)
        const handleHide = (event: Event) => handleCollapseToggle(event, false)

        window.$(document).on("show.bs.collapse", handleShow)
        window.$(document).on("hide.bs.collapse", handleHide)

        return () => {
            window.$(document).off("show.bs.collapse", handleShow)
            window.$(document).off("hide.bs.collapse", handleHide)
        }
    }

    useEffect(() => {
        listenCollapseEvents()
        fetchData()
    }, [])

    useEffect(() => { window.$('[data-toggle="tooltip"]').tooltip()})

    return (
        <div className="container-fluid mb-2">
            <div className="row">
                <div className="col">
                    <h2 className="mb-4">Paramétrage Plaques</h2>
                    <div className="my-4 d-flex justify-content-between">
                        <button type="button" className="btn btn-light" onClick={openCreateModal}
                                data-toggle="modal" data-target="#modalCreateGroupement"
                        >
                            <i className="fa fa-plus"></i> Créer une Plaque
                        </button>
                    </div>
                    {!isLoading ? groupsDealerships.map((groupDealerships) =>
                            <div className={"card my-4"} key={groupDealerships.groupId}>
                                <div style={{ cursor: 'pointer' }} className={"card-header d-flex flex-row justify-content-between border-0"}
                                     id={`heading-${groupDealerships.groupId}`}
                                     onClick={() => toggleCollapse(groupDealerships.groupId ?? 0)}
                                >
                                    <div className={"d-flex flex-row align-items-center"} style={{ gap: "1.5em" }}>
                                        <span style={{ fontSize: '1.2em' }}>{groupDealerships.groupName}</span>
                                        <i
                                            style={{ color: '#acafb5' }}
                                            className={`fa-solid ${
                                                isCollapsed(groupDealerships.groupId ?? 0)
                                                    ? "fa-chevron-right"
                                                    : "fa-chevron-down"
                                            }`}
                                        ></i>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary btn-sm"
                                                onClick={(event) => {
                                                    openEditModal(groupDealerships)
                                                    event.stopPropagation()
                                                }}
                                        >
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                        <button className="btn btn-danger btn-sm ml-2"
                                                onClick={(event) => {
                                                    openConfirmationToDeleteModal(groupDealerships)
                                                    event.stopPropagation()
                                                }}
                                        >
                                            <i className="fa fa-xmark"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className={"collapse"} id={`collapse-${groupDealerships.groupId}`}>
                                    <div className={"card-body"}>
                                        <table className={"table table-sm"}>
                                            <thead>
                                            <tr>
                                                <th>Sites concernés</th>
                                                <th>Visibilité Stock</th>
                                                <th>Visibilité Affaires</th>
                                                <th>Visibilité Offres</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {groupDealerships.dealerships.map((dealership) =>
                                                <tr key={dealership.value}>
                                                    <td>
                                                        <span className={""}>{dealership.label}</span>
                                                    </td>
                                                    <td>
                                                        {getBadgeToShow(dealership.canSeeAllStock ?? false, dealership, "accessDealershipsStock")}
                                                    </td>
                                                    <td>
                                                        {getBadgeToShow(dealership.canSeeAllLeads ?? false, dealership, "accessDealershipsLeads")}
                                                    </td>
                                                    <td>
                                                        {getBadgeToShow(dealership.canSeeAllOffers ?? false, dealership, "accessDealershipsOffers")}
                                                    </td>
                                                </tr>,
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>,
                        ) :
                        <div className={"d-flex w-100 justify-content-center mt-5"}>
                            <span><i style={{ fontSize: '2em' }} className="fa fa-spinner fa-spin fa-fw"></i>&nbsp;</span>
                        </div>
                    }
                </div>
            </div>
            <ModalCreateGroupement
                dealerships={dealerships}
                isEditing={isEditing}
                selectedGroupDealerships={selectedGroupDealerships}
                createGroupDealerships={createGroupDealerships}
                updateGroupDealerships={updateGroupDealerships}
                cancelEdit={() => {
                    setIsEditing(false)
                    setSelectedGroupDealerships(null)
                }}
            />
            <ModalDeleteGroupement
                groupDealershipsName={groupsDealershipsToDelete?.groupName ?? ""}
                refuseToDelete={() => setGroupsDealershipsToDelete(null)}
                deleteGroupDealerships={deleteGroupDealerships}
            />
        </div>
    )
}
