export class Origine
{
    IDOrigineAction!: number
    LibelleOrigine!: string
    LibelleSousFamille!: string
    LibelleFamille!: string
    DescOrigineActionType!: string

    constructor(data?: Partial<Origine>) {
        Object.assign(this, data)
    }


    getLabel = () => {
        if(this.IDOrigineAction === -1){
            return this.LibelleOrigine
        } else {
            return this.LibelleFamille + " - " + this.LibelleSousFamille + " - " + this.LibelleOrigine+" ("+this.DescOrigineActionType+")"
        }
    }

    getValue = () => {
        return this.IDOrigineAction
    }


}