import React from "react"

interface Props {
    groupDealershipsName?: string
    refuseToDelete: () => void
    deleteGroupDealerships: () => void
}

export function ModalDeleteGroupement(props: Props) {
    return (
        <div id="modalDeleteGroupement" className="modal fade" tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content border-0">
                    <div className="modal-header p-2 bg-light">
                        <h4 className="modal-title">Supprimer : { props.groupDealershipsName }</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>La suppression de cette plaque est définitive, êtes-vous sûr de vouloir continuer ?</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                        <button type="button" onClick={props.deleteGroupDealerships} className="btn btn-danger" data-dismiss="modal">
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
