import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Site } from "../../../../types/Site"
import { SiteMarqueWally } from "../Types/Class/SiteMarqueWally"
import { SiteWally } from "../Types/Class/SiteWally"
import { titleTypes } from "../Types/TitleType"
import { MarqueWally } from "../Types/Class/MarqueWally"
import Mapping from "../MappingComponents/Mapping"
import { CompteWally } from "../Types/Class/CompteWally"
import { AccountParam } from "../Types/AccountParam"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import { notAddedMapping } from "../Types/titleMapping"

export default function Index() {
    const history = useHistory();
    const params: AccountParam = useParams<AccountParam>()
    const [isFilled, setIsFilled] = useState<boolean>(false)

    const transformedDataSiteESeller = (data: any) => {
        return data.map((site: any) => new Site(site)).sort((a: any, b: any) => {
            const nameA = a.Descr // ignore upper and lowercase
            const nameB = b.Descr // ignore upper and lowercase
            if(nameA == notAddedMapping["TITLE"]){
                return -1
            }
            if(nameB == notAddedMapping["TITLE"]){
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            // names must be equal
            return 0
        })
    }

    const transformedDataSiteMarquesWally = (data: any, sites: any) => {
        return data
            .map((pdv: any) => new SiteMarqueWally(pdv, sites))
            .sort((a: any, b: any) => {
                let nameA = a.SiteDescr // ignore upper and lowercase
                let nameB = b.SiteDescr // ignore upper and lowercase


                if(nameA === undefined ) nameA = "zzzzzzzzz"
                if(nameB === undefined ) nameB = "zzzzzzzzz"
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
    }

    const transformedDataMarquesWally = (data: any) => {
        return data.map((mq: any) => new MarqueWally(mq))
    }

    const transformedDataSiteWally = (data: any) => {
        return data.map((site: any) => new SiteWally(site))
    }

    const handleNextPage = () => {
        if(!isFilled){
            alert("Les correspondances des points de vente sont obligatoires.\nNous vous prions de bien vouloir terminer de les ajouter pour passer à l'étape suivante")
            return
        }

        history.push("/interfaces/wally/" +
            params.crm_name + "/comptes/creation/" + params.account_id + "/mapping-origines")
    }

    const contentHeader = () => {
        return <div className={"row mb-2"}>
            <div className={"col"}>

                <div className={"d-flex d-inline justify-content-between"}>
                    <ButtonAction href={"../../creation?account_id=" + params.account_id} className={"btn btn-secondary"}>
                        <>
                            <i className={"fa-solid fa-chevron-left"}/>
                            <i className={"fa-solid fa-chevron-left"}/>
                        </>
                    </ButtonAction>

                    <ButtonAction2 className={"btn btn-success"}
                                   onClick={handleNextPage}
                    >
                        <>
                            <span>Suivant</span>
                        </>
                    </ButtonAction2>
                </div>
            </div>
        </div>

    }

    return <Mapping
        title={"Point de vente " + titleTypes[params.crm_name]}
        crm_title={titleTypes[params.crm_name]}
        crm_name={params.crm_name}
        backLink={null}
        toggleReload={false}
        toggleAddItem={false}
        isDeleteMapping={false}

        // Account
        accounts={[]}
        accountSelected={new CompteWally({ IdWallyCompte: params.account_id })}
        loadAccount={false}

        // Wally Merged
        wallyMerged_urlToFetchData={(account_id: string) => '/interfaces/wally/accounts/' + account_id + '/marques-sites'}
        wallyMerged_urlToSaveData={(account_id: string) => '/interfaces/wally/accounts/' + account_id + '/marques-sites'}
        wallyMerged_urlToRefreshData={(account_id: string) => '/interfaces/wally/accounts/' + account_id + '/marques-sites/refresh'}

        wallyMerged_transformedData={transformedDataSiteMarquesWally}
        wallyMerged_errorMsgFetch={"sites/marques"}

        // Wally
        wally_columnTitle={"Point de vente ["+ titleTypes[params.crm_name]+"]"}
        wally_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/sites'}
        wally_transformedData={transformedDataSiteWally}
        wally_errorMsgFetch={"sites"}
        wally_select_placeHolder={"le site"}


        // Marques
        marques_columnTitle={"Marques ["+ titleTypes[params.crm_name]+"]"}
        marques_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques'}
        marques_transformedData={transformedDataMarquesWally}
        marques_errorMsgFetch={"marques"}
        marques_select_placeHolder={"la marque"}


        // ESeller
        eSeller_urlToFetchData={'/interfaces/wally/sites'}
        eSeller_columnTitle={"Point de vente [ESeller]"}
        eSeller_errorMsgFetch={"sites"}
        eSeller_transformedData={transformedDataSiteESeller}
        eSeller_select_placeHolder={"le site ESeller"}

        // DOM
        contentHeader={contentHeader}

        setIsFilled={setIsFilled}

    />
}
