import { StateEnumLabel } from "../Enum/StateEnumLabel"
import { FlagEnum } from "../Enum/FlagEnum"

export class CompteWally
{
    IdWallyCompte!: string
    Identifiant!: string
    MotDePasse!: string
    EtatDuCompte!: keyof typeof StateEnumLabel
    EmailNotification!: string
    Flag?: keyof typeof FlagEnum
    settingPassword ?: boolean
    settingEmail ?: boolean
    newPassword ?: string
    newEmail ?: string
    isPasswordHidden ?: boolean
    savingPassword ?: boolean
    savingEmail ?: boolean
    emailsAdded: string[]

    constructor(data?: Partial<CompteWally>) {
        Object.assign(this, data)
        this.settingPassword = false
        this.settingEmail = false
        this.newPassword = this.MotDePasse
        this.newEmail = this.EmailNotification
        this.isPasswordHidden = true
        this.savingPassword = false
        this.savingEmail = false;
        this.emailsAdded = !this.EmailNotification ? [] : this.EmailNotification.split(";")

    }

    emailNotificationToArray = () => {
        return this.EmailNotification.split(";")
    }
}