import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { MarqueWally } from "../Types/Class/MarqueWally"
import { titleTypes } from "../Types/TitleType"
import { Origine } from "../../../../types/Origine"
import { OrigineWally } from "../Types/Class/OrigineWally"
import { OrigineMarqueWally } from "../Types/Class/OrigineMarqueWally"
import Mapping from "../MappingComponents/Mapping"
import { AccountParam } from "../Types/AccountParam"
import { CompteWally } from "../Types/Class/CompteWally"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"

export default function Index() {
    const backofficeApiClient = new BackOfficeAPIClient()
    const params:AccountParam = useParams<AccountParam>()

    useEffect(() => {
        importOriginToDefault()
    }, [])

    const transformedDataOriginESeller = (data:any) => {
        return data
            .map((ori:any) => new Origine(ori))
            .sort((a:any,b:any) => {
                const nameA = `${a.LibelleFamille}${a.LibelleSousFamille}`.toLowerCase()
                const nameB =`${b.LibelleFamille}${b.LibelleSousFamille}`.toLowerCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
    }

    const transformedDataOriginWally = (data:any) => {
        return data
            .map((ow:any) => new OrigineWally(ow))
            .sort((a:any,b:any) => {
                const nameA = a.Nom.toLowerCase()
                const nameB = b.Nom.toLowerCase()
                if(nameA == "origine constructeur vide"){
                    return -1
                }
                if(nameB == "origine constructeur vide"){
                    return 1
                }
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
    }

    const transformedDataOriginMarqueWally = (data:any,origines:any) => {
        return data
            .map((ome:any) => new OrigineMarqueWally(ome, origines))
            .sort((a:any,b:any) => {
                const nameA = a.origineESBO?.LibelleFamille
                const nameB = b.origineESBO?.LibelleFamille

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
    }


    const importOriginToDefault = () => {
        backofficeApiClient
            .put<any>("/interfaces/wally/accounts/creation/"+params.account_id+"/mapping-origins",
                {})
            .then((r) => {
                console.log(r)
            }).catch((e) => {
            console.log(e)
        })
    }

    const transformedDataMarquesWally = (data:any) => {
        return data.map((mq: any) => new MarqueWally(mq))
    }

    const contentHeader = () => {
        const history = useHistory();
        return <div className={"row mb-2"}>
            <div className={"col"}>

                <div className={"d-flex d-inline justify-content-between"}>
                    <ButtonAction href={"../../creation/"+ params.account_id + "/mapping-point-de-vente"} className={"btn btn-secondary"}>
                        <>
                            <i className={"fa-solid fa-chevron-left"}/>
                            <i className={"fa-solid fa-chevron-left"}/>
                        </>
                    </ButtonAction>

                    <ButtonAction2 className={"btn btn-success"}
                                   onClick={() => history.push("/interfaces/wally/" +
                                       params.crm_name + "/comptes/creation/" + params.account_id + "/activation")}
                    >
                        <>
                            <span>Suivant</span>
                        </>
                    </ButtonAction2>
                </div>
            </div>
        </div>

    }

    return <Mapping
        title={"Origine " + titleTypes[params.crm_name]}
        crm_title={titleTypes[params.crm_name]}
        crm_name={params.crm_name}
        backLink={null}
        toggleReload={false}
        toggleAddItem={true}

        // Account
        accounts={[]}
        accountSelected={new CompteWally({IdWallyCompte:params.account_id})}
        loadAccount={false}

        // Wally Merged
        wallyMerged_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques-origines'}
        wallyMerged_urlToSaveData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques-origines'}
        wallyMerged_urlToRefreshData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques-origines/refresh'}

        wallyMerged_transformedData={transformedDataOriginMarqueWally}
        wallyMerged_errorMsgFetch={"origines/marques"}

        // Marques
        marques_columnTitle={"Marques ["+ titleTypes[params.crm_name]+"]"}
        marques_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques'}
        marques_transformedData={transformedDataMarquesWally}
        marques_errorMsgFetch={"marques"}
        marques_select_placeHolder={"la marque"}


        // Origines
        wally_columnTitle={"Origines ["+ titleTypes[params.crm_name]+"]"}
        wally_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/origines'}
        wally_transformedData={transformedDataOriginWally}
        wally_errorMsgFetch={"origines"}
        wally_select_placeHolder={" l'origine"}

        // ESeller
        eSeller_urlToFetchData={'/interfaces/wally/origines'}
        eSeller_columnTitle={"Origines [ESeller]"}
        eSeller_errorMsgFetch={"origines"}
        eSeller_transformedData={transformedDataOriginESeller}
        eSeller_select_placeHolder={"le site ESeller"}

        // DOM
        contentHeader={contentHeader}

    />
}
