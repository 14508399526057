import { Site } from "../../../../../types/Site"
import { notAddedMapping } from "../titleMapping"

export class SiteMarqueWally
{
    NomWallySite!: string
    IdWallySite!: number
    NomWallyMarque!: string
    IdWallyMarque!: number
    IdCompte!: string
    IDSite!: number | null
    toSkip!: boolean
    SiteDescr!: string
    Site?: Site

    isCurrentChange?: boolean
    IDSiteModifying?: number | null

    constructor(data?: Partial<SiteMarqueWally>, sites?: Site[]) {
        Object.assign(this, data)
        if (this.IDSite && sites) {
            this.Site = sites.find(s => s.IDSite === this.IDSite)
        }
        this.IDSiteModifying = this.IDSite ;

        if(this.toSkip === undefined) {
            this.toSkip = false;
        }
        if(this.toSkip) {
            this.IDSiteModifying = notAddedMapping["ID"];
        }

        this.isCurrentChange = this.IDSite === null && !this.toSkip;
    }

    getPrimaryKey = () => {
        return this.IdWallyMarque + "_" + this.IdWallySite
    }

    getESellerId = () => {
        if(this.toSkip){
            return notAddedMapping["TITLE"]
        }
        return this.IDSite;
    }


    getTitleWally = () => {
        return this.NomWallySite
    }

    getTitleMarque = () => {
        return this.NomWallyMarque
    }

    getTitleESeller = () => {
        if(this.toSkip){
            return notAddedMapping["TITLE"]
        }
        return this.SiteDescr
    }

    getIdModifying = () => {
        return this.IDSiteModifying
    }

    setIdModifying = (id:number|null|undefined) => {
        return this.IDSiteModifying = id;
    }

    resetIdModifying = () => {
        this.IDSiteModifying = this.IDSite
        if(this.toSkip) {
            this.IDSiteModifying = notAddedMapping["ID"];
        }
        return this.IDSiteModifying;
    }
    getUrlToSave = (account_id:string) =>
        '/interfaces/wally/accounts/' + account_id + "/marques-sites/" + this.IdWallySite + "/" + this.IdWallyMarque;

    getUrlToDelete = (account_id:string) =>
        '/interfaces/wally/accounts/' + account_id + "/marques-sites/" + this.IdWallySite + "/" + this.IdWallyMarque


    getDataToSave = () => {
        return { idSite: this.getIdModifying() }
    }

}


