import React, { useState, useEffect } from "react"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import { ButtonAction } from "../../../UtilsComponents/ButtonAction"
import { CompteWally } from "../Types/Class/CompteWally"
import { AccountStateColors } from "../Types/Enum/AccountStateColors"
import { StateEnumLabel } from "../Types/Enum/StateEnumLabel"
import { Link, useParams } from "react-router-dom"
import { CrmParam } from "../Types/CrmParam"
import HeaderPageWally from "../MappingComponents/HeaderPageWally"
import { ReactMultiEmail } from "react-multi-email"
import "react-multi-email/dist/style.css";
import FetchProgress from "../Commons/FetchProgress"

export default function Index() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const params: CrmParam = useParams<CrmParam>()
    const [ accounts, setAccounts ] = useState<CompteWally[]>([])
    const [ fetchProgress, setFetchProgress ] = useState<boolean>(false)
    const [ fetchProgressActivation, setFetchProgressActivation ] = useState<Record<string,boolean>>({})
    const [ fetchProgressDeactivation, setFetchProgressDeactivation ] = useState<Record<string,boolean>>({})
    const [ errorsPassword, setErrorsPassword ] = useState<Record<string,{color: string, message: string}>>({})
    const [ errorsEmail, setErrorsEmail ] = useState<Record<string,{color: string, message: string}>>({})
    const [ errorsActivation, setErrorsActivation ] = useState<Record<string,{color: string, message: string}>>({})

    useEffect(() => {
        fetchAccount()
    }, [])

    const fetchAccount = async () => {
        setFetchProgress(true)
        try {
            const accountData: CompteWally[] = await backofficeApiClient.get<CompteWally[]>('/interfaces/wally/'+params.crm_name+'/accounts')
            const transformedAccounts = accountData.filter(account => account.EtatDuCompte !== "DEFAULT")
                                                   .map(account => new CompteWally(account))
            setAccounts(transformedAccounts)
        } catch (err) {
            // Gestion des erreurs
            console.error('Erreur lors de la récupération des comptes:', err)
        } finally {
            // Toujours exécuter, succès ou échec
            setFetchProgress(false)
        }
    }

    // --- DELETING ACCOUNT
    const handleDelete = (acc: CompteWally) => {
        return async () => {
            if (confirm("Voulez vous vraiment supprimer ce compte ?")) {
                try {
                    const data = await backofficeApiClient.delete<any>(`/interfaces/wally/accounts/${acc.IdWallyCompte}`, null)
                } catch (err) {
                    // Gestion de l'erreur
                    console.error(`Erreur lors de la suppression du compte ${acc.Identifiant} :`, err)
                } finally {
                    await fetchAccount()
                }

                return
            }
        }
    }

    // ---  SETTING PASSWORD
    const handleSetPassword = (e: any) => {
        const acc_id = e.currentTarget.getAttribute("data-account-id")
        const accountToUpdate = accounts.find(acc => {return acc.IdWallyCompte == acc_id}) as CompteWally
        if (!accountToUpdate) return
        const newSettingPassword = !accountToUpdate.settingPassword
        const newPassword = newSettingPassword ? accountToUpdate.MotDePasse : ""
        const updatedAccount: CompteWally = {
            ...accountToUpdate,
            settingPassword: newSettingPassword,
            newPassword: newPassword,
        }
        setAccounts(prevAccounts =>
            prevAccounts.map(account =>
                account.IdWallyCompte == acc_id ? updatedAccount : account),
        )
    }

    const onChangePassword = (e: any) => {
        setErrorsPassword((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors["account_"+e.target.getAttribute("data-account-id")];
            return updatedErrors;
        });
        setAccounts(accounts.map((acc) =>
            acc.IdWallyCompte == e.target.getAttribute("data-account-id") ?
                { ...acc, settingPassword: true, newPassword: e.currentTarget.value } : acc,
        ))
    }
    const onCancelNewPassword = (e: any) => {
        setAccounts(accounts.map((acc) =>
            acc.IdWallyCompte == e.target.getAttribute("data-account-id") ? { ...acc, settingPassword: false, newPassword: "" } : acc,
        ))
    }

    const toggleHiddenPassword = (e: any) => {
        setAccounts(accounts.map((acc) =>
            acc.IdWallyCompte == e.target.getAttribute("data-account-id") ? { ...acc, isPasswordHidden: !acc.isPasswordHidden } : acc,
        ))
    }

    const savePassword = async (acc_id: string) => {
        const accountToUpdate = accounts.find(acc => acc.IdWallyCompte === acc_id)
        if (accountToUpdate === undefined) return

        const passwordValue = accountToUpdate.newPassword
        toggleLoaderSetPassword(acc_id, true)

        try {
            const response = await backofficeApiClient.put<any>(`/interfaces/wally/accounts/${acc_id}`, {
                password: passwordValue,
                state: "ACTIVE",
                flag: "FULL",
            })

            // Mise à jour de l'account avec les données renvoyées par la requête
            accountToUpdate.settingPassword = false
            accountToUpdate.MotDePasse = response.password
            accountToUpdate.newPassword = response.password
            accountToUpdate.EtatDuCompte = response.state
            accountToUpdate.Flag = response.flag

            // Mise à jour de la liste des comptes
            setAccounts(prevAccounts =>
                prevAccounts.map(account =>
                    account.IdWallyCompte === acc_id
                        ? { ...account, ...accountToUpdate }
                        : account,
                ),
            )
            setErrorsPassword((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors["account_"+acc_id];
                return updatedErrors;
            });

        } catch (err:any) {
            if(JSON.parse(err.message).message){
                setErrorsPassword({...errorsPassword, ["account_"+acc_id] : {color :"lightcoral", message: JSON.parse(err.message).message}})
                // Mise à jour de l'account avec les données renvoyées par la requête
                accountToUpdate.EtatDuCompte = JSON.parse(err.message).state
                // Mise à jour de la liste des comptes
                setAccounts(prevAccounts =>
                    prevAccounts.map(account =>
                        account.IdWallyCompte === acc_id
                            ? { ...account, ...accountToUpdate }
                            : account,
                    ),
                )
            }
            // Gestion de l'erreur
            await fetchAccount()
            console.error(`Erreur lors de l'enregistrement du mot de passe sur le compte ${accountToUpdate.Identifiant}:`, err)
        } finally {
            // Désactiver le loader
            toggleLoaderSetPassword(acc_id, false)
        }
    }

    const closureSavePassword = (id: string) => {
        return () => {
            savePassword(id)
        }
    }

    const toggleLoaderSetPassword = (acc_id: string, isLoading: boolean) => {
        setAccounts(accounts.map((acc) =>
            acc.IdWallyCompte == acc_id ? { ...acc, savingPassword: isLoading } : acc,
        ))
    }


    // --- SETTING EMAIL
    const handleSetEmail = (e: any) => {
        const acc_id = e.currentTarget.getAttribute("data-account-id")
        const accountToUpdate = accounts.find(acc => {return acc.IdWallyCompte == acc_id}) as CompteWally
        if (!accountToUpdate) return
        const newSettingEmail = !accountToUpdate.settingEmail
        const newEmail = newSettingEmail ? accountToUpdate.EmailNotification : ""
        const updatedAccount: CompteWally = {
            ...accountToUpdate,
            settingEmail: newSettingEmail,
            newEmail: newEmail,
        }
        setErrorsActivation((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors["account_"+acc_id];
            return updatedErrors;
        });
        setAccounts(prevAccounts =>
            prevAccounts.map(account =>
                account.IdWallyCompte == acc_id ? updatedAccount : account),
        )
    }

    const onCancelNewEmail = (e: any) => {
        setAccounts(accounts.map((acc) =>
            acc.IdWallyCompte == e.target.getAttribute("data-account-id") ? { ...acc, settingEmail: false, newEmail: "" } : acc,
        ))
    }

    const closureSaveEmail = (id: string) => {
        return () => {
            saveEmail(id)
        }
    }

    const saveEmail = async (acc_id: string) => {

        const accountToUpdate = accounts.find(acc => acc.IdWallyCompte === acc_id)
        if (accountToUpdate === undefined){
            return
        }
        const emailValues = accountToUpdate.emailsAdded;

        // Vérification de l'email
        emailValues.forEach(emailValue => {
            if(checkIsErrorEmail(emailValue, acc_id))  return;
        })
        if(emailValues.length === 0) {
            setErrorsEmail({...errorsEmail,
                ["account_"+acc_id] : {
                    color :"lightcoral",
                    message: "Au moins une adresse mail obligatoire"
                }
            })
            return
        }
        toggleLoaderSetEmail(acc_id, true)
        try {
            const response = await backofficeApiClient.put<any>(`/interfaces/wally/accounts/${acc_id}/email`, {
                emailNotification: emailValues.join(";"),
            })
            // Mise à jour de l'account avec les données renvoyées par la requête
            accountToUpdate.EmailNotification = response.emailNotification

            // Mise à jour de la liste des comptes
            setAccounts(prevAccounts =>
                prevAccounts.map(account =>
                    account.IdWallyCompte === acc_id
                        ? { ...account, ...accountToUpdate }
                        : account,
                ),
            )
        } catch (err) {
            // Gestion de l'erreur
            await fetchAccount()
            console.error(`Erreur lors de l'enregistrement de l'email sur le compte ${accountToUpdate.Identifiant}:`, err)
        } finally {
            // Désactiver le loader
            toggleLoaderSetEmail(acc_id, false)
        }
    }

    const toggleLoaderSetEmail = (acc_id: string, isLoading: boolean) => {
        setAccounts(accounts.map((acc) =>
            acc.IdWallyCompte == acc_id ? { ...acc, savingEmail: isLoading,settingEmail:isLoading } : acc,
        ))
    }

    /**
     * Return true if on error
     * @param emailToVerify
     * @param acc_id
     */
    const checkIsErrorEmail = (emailToVerify: string|undefined, acc_id:string) => {
        if(emailToVerify === undefined) return
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pour email
        if(!emailRegex.test(emailToVerify)){
            setErrorsEmail({...errorsEmail,
                ["account_"+acc_id] : {
                    color :"lightcoral",
                    message: "Veuillez saisir une adresse mail valide"
                }
            })
            return true;
        }
        return false;
    }

    /**
     * Return true if on error
     * @param emailToVerify
     * @param acc_id
     */
    const checkIsActivation = (emailToVerify: string|undefined, acc_id:string) => {
        if(emailToVerify === undefined) return
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pour email
        if(!emailRegex.test(emailToVerify)){
            setErrorsActivation({...errorsEmail,
                ["account_"+acc_id] : {
                    color :"lightcoral",
                    message: "Veuillez saisir une adresse mail avant activation du compte"
                }
            })
            return true;
        }
        return false;
    }

    const handleToggleActive = (accToUpdate: CompteWally, toggleActive: string) => {
        return () => {

            setErrorsPassword((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors["account_"+accToUpdate.IdWallyCompte];
                return updatedErrors;
            });
            if(accToUpdate.emailNotificationToArray().length === 0){
                setErrorsActivation({...errorsEmail,
                    ["account_"+accToUpdate.IdWallyCompte] : {
                        color :"lightcoral",
                        message: "Veuillez saisir au moins une adresse mail avant activation du compte"
                    }
                })
                return;
            }
            for(const email of accToUpdate.emailNotificationToArray()){
                if(checkIsActivation(email, accToUpdate.IdWallyCompte))  return;
            }

            if(toggleActive === "ACTIVE"){
                setFetchProgressActivation({...fetchProgressActivation,
                    ["account_"+accToUpdate.IdWallyCompte] :true})
            } else{
                setFetchProgressDeactivation({...fetchProgressDeactivation,
                    ["account_"+accToUpdate.IdWallyCompte] :true})
            }

            backofficeApiClient.put<any>(`/interfaces/wally/accounts/${accToUpdate.IdWallyCompte}`, {
                password: accToUpdate.MotDePasse,
                state: toggleActive,
                flag: toggleActive === "ACTIVE" ? "FULL" : null,
            }).then((response) => {
                accToUpdate.EtatDuCompte = response.state
                accToUpdate.Flag = response.flag
                setAccounts(prevAccounts =>
                    prevAccounts.map(account =>
                        account.IdWallyCompte === accToUpdate.IdWallyCompte
                            ? { ...account, ...accToUpdate }
                            : account,
                    ),
                )
            }).catch(err => {
                console.log(err)
                const errorJson = JSON.parse(err.message);
                if(errorJson.message && errorJson.state === "OUTDATED") {
                    setErrorsPassword({
                        ...errorsPassword, ["account_" + accToUpdate.IdWallyCompte]:
                            { color: "lightcoral", message: "Mot de passe incorrect, activation du compte impossible" }
                    })
                } else if(errorJson.message) {
                    alert(errorJson.message)
                }
                // Mise à jour de l'account avec les données renvoyées par la requête
                accToUpdate.EtatDuCompte = errorJson.state ?? accToUpdate.EtatDuCompte;
                // Mise à jour de la liste des comptes
                setAccounts(prevAccounts =>
                    prevAccounts.map(account =>
                        account.IdWallyCompte === accToUpdate.IdWallyCompte
                            ? { ...account, ...accToUpdate }
                            : account,
                    ),
                )

            }).finally(() => {
                setFetchProgressActivation({...fetchProgressActivation,
                    ["account_"+accToUpdate.IdWallyCompte] :false})
                setFetchProgressDeactivation({...fetchProgressActivation,
                    ["account_"+accToUpdate.IdWallyCompte] :false})
            })
        }
    }

    const styles = {
        fontFamily: "sans-serif",
        width: "500px",
        border: "1px solid #eee",
        background: "#f3f3f3",
        padding: "5px",
        margin: "0px"
    };


    const lineAccount = (acc: CompteWally) => {
        return <tr key={acc.IdWallyCompte}>
            <td>
                {acc.Identifiant} ({acc.IdWallyCompte})
            </td>
            <td>
                <div className={"d-flex flex-row align-items-center"}>
                    <div className={"flex-grow-1 "}>
                        {acc.settingPassword == true ?
                            <>
                                <div className={"position-relative"}>

                                    <input type={acc.isPasswordHidden ? "password" : "text"}
                                           readOnly={!acc.settingPassword} onChange={onChangePassword}
                                           className={"form-control form-control-sm p-2 pr-5"}
                                           id={"password[" + acc.IdWallyCompte + "]"} data-account-id={acc.IdWallyCompte} name={"password[" + acc.IdWallyCompte + "]"}
                                           value={acc.settingPassword ? acc.newPassword : acc.MotDePasse}/>


                                    <i onClick={toggleHiddenPassword} role="button" data-account-id={acc.IdWallyCompte}
                                       className={"fa-solid p-2 " + (acc.isPasswordHidden ? " fa-eye" : " fa-eye-slash")}
                                       style={{
                                           color: "darkgray",
                                           position: "absolute",
                                           right: "10px",
                                           top: "50%",
                                           transform: "translateY(-50%)",
                                           cursor: "pointer",
                                       }}>
                                    </i>
                                </div>
                                {errorsPassword["account_" + acc.IdWallyCompte] &&
                                    <small style={{ color: errorsPassword["account_" + acc.IdWallyCompte].color }}>
                                        <b>{errorsPassword["account_" + acc.IdWallyCompte].message} </b>
                                    </small>
                                }
                            </>
                            :
                            <>
                                <span className="ml-2 bold">{'•'.repeat(acc.MotDePasse.length)}</span>

                            </>
                        }

                    </div>
                    <div>
                        {acc.settingPassword == true ?
                            <>
                                <ButtonAction href="#" onClick={closureSavePassword(acc.IdWallyCompte)}>
                                    {acc.savingPassword === false ?
                                        <i role="button" data-account-id={acc.IdWallyCompte} className={"fa-solid fa-square-check fa-lg p-2 "} style={{ color: "green" }}></i>
                                        :
                                        <span><i className="fa fa-spinner fa-spin fa-fw" style={{ color: "green" }}></i>&nbsp;</span>
                                    }
                                </ButtonAction>
                                <ButtonAction href="#" onClick={onCancelNewPassword}>
                                    <i role="button" data-account-id={acc.IdWallyCompte} className={"fa-solid fa-circle-xmark fa-lg p-2"} style={{ color: "grey" }}></i>
                                </ButtonAction>
                            </>
                            :
                            <ButtonAction href="#" onClick={handleSetPassword}
                                          data-account-id={acc.IdWallyCompte}
                                          className={"text-reset p-2"}>
                                <i role="button" className={"fa-solid fa-pen"}></i>
                            </ButtonAction>

                        }
                    </div>
                </div>
            </td>
            <td>
                <div className={"d-flex flex-row align-items-center"}>
                    <div className={"flex-grow-1 position-relative"}  style={styles}>
                        {acc.settingEmail == true ?
                            <div>
                                <ReactMultiEmail
                                    placeholder='Renseignez une/des adresse(s) mail(s) valide'
                                    emails={acc.emailsAdded}
                                    onChange={(_emails: string[] ) => {
                                        setAccounts(accounts.map((accState) =>
                                            accState.IdWallyCompte == acc.IdWallyCompte ?
                                                { ...accState, emailsAdded: _emails} : accState,
                                        ))
                                    }}
                                    delimiter={';'}
                                    getLabel={(
                                        email:string,
                                        index: number,
                                        removeEmail : (index: number) => void
                                    ) => {
                                        return (
                                            <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                />
                                {errorsEmail["account_" + acc.IdWallyCompte]  &&
                                    <small style={{ color: errorsEmail["account_" + acc.IdWallyCompte].color }}>
                                        <b>{errorsEmail["account_" + acc.IdWallyCompte].message} </b>
                                    </small>
                                }
                            </div>
                            :
                            <>
                                <span className="ml-2 bold">{acc.EmailNotification}</span>
                            </>
                        }
                    </div>
                    <div>
                        {acc.settingEmail == true ?
                            <>
                                <ButtonAction href="#" onClick={closureSaveEmail(acc.IdWallyCompte)}>
                                    {acc.savingEmail === false ?
                                        <i role="button" data-account-id={acc.IdWallyCompte} className={"fa-solid fa-square-check fa-lg p-2 "} style={{ color: "green" }}></i>
                                        :
                                        <span><i className="fa fa-spinner fa-spin fa-fw" style={{ color: "green" }}></i>&nbsp;</span>
                                    }
                                </ButtonAction>
                                <ButtonAction href="#" onClick={onCancelNewEmail}>
                                    <i role="button" data-account-id={acc.IdWallyCompte} className={"fa-solid fa-circle-xmark fa-lg p-2"} style={{ color: "grey" }}></i>
                                </ButtonAction>
                            </>
                            :
                            <ButtonAction href="#" onClick={handleSetEmail} data-account-id={acc.IdWallyCompte} className={"text-reset p-2"}>
                                <i role="button" className={"fa-solid fa-pen"}></i>
                            </ButtonAction>
                        }
                    </div>

                </div>
            </td>
            <td>
                <span className={"font-weight-bold"} style={{ color: AccountStateColors[StateEnumLabel[acc.EtatDuCompte]] }}>{StateEnumLabel[acc.EtatDuCompte]}</span>
            </td>
            <td className={"d-flex flex-column"}>
                <div className={"d-flex flex-row"}>
                    {acc.EtatDuCompte === "ACTIVE" ?
                        <ButtonAction href="#" onClick={handleToggleActive(acc,"INACTIVE")} className={"p-2 flex-fill fa-lg "}>


                            {fetchProgressDeactivation["account_" + acc.IdWallyCompte] ? <span><i className="fa fa-spinner fa-spin fa-fw" style={{ color: "lightblue" }}></i>&nbsp;</span> :
                                <i className={"fa-solid fa-pause"} style={{ color: "lightblue" }}></i>
                            }
                        </ButtonAction>
                        :
                        <ButtonAction href="#" onClick={handleToggleActive(acc,"ACTIVE")} className={"p-2 flex-fill fa-lg"}>
                            {fetchProgressActivation["account_" + acc.IdWallyCompte] ? <span><i className="fa fa-spinner fa-spin fa-fw" style={{ color: "lightseagreen" }}></i>&nbsp;</span> :
                                <i className={"fa-solid fa-play"} style={{ color: "lightseagreen" }}></i>
                            }
                        </ButtonAction>
                    }
                    <ButtonAction href="#" onClick={handleDelete(acc)} className={"flex-fill p-2 text-decoration-none text-reset"}>
                        <i className={"fa-solid fa-trash fa-lg "} style={{ color: "lightcoral" }}></i>
                    </ButtonAction>
                </div>
                <div>
                    {errorsActivation["account_" + acc.IdWallyCompte]  &&
                        <small style={{ color: errorsActivation["account_" + acc.IdWallyCompte].color }}>
                            <b>{errorsActivation["account_" + acc.IdWallyCompte].message} </b>
                        </small>
                    }
                </div>
            </td>
        </tr>
    }

    return <>
        <div className={"container-fluid mb-2 pr-5 pl-5"}>

            <div className={"row"}>
                <div>
                    <ButtonAction href={"/interfaces/wally/"} className={"btn btn-secondary"}>
                        <i className={"fa-solid fa-chevron-left fa-xs"}></i>
                    </ButtonAction>
                </div>
                <HeaderPageWally title={"Compte"}/>
            </div>

            <div className={"row mb-4"}>
                <div className={"col"}>
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <Link to={"/interfaces/wally/"+params.crm_name+"/comptes/creation"}  type="button" className="btn btn-secondary">
                            <i className={"fa-solid fa-plus fa-xs"}></i>
                            <span className={"pl-2"}>Ajouter</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={"row mt-3"}>
                <div className={"col"}>
                    <div className={"text-center"}>
                        <FetchProgress active={fetchProgress} />
                    </div>
                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th className={"w-25"}>Compte</th>
                            <th className={"w-auto"}>Mot de passe</th>
                            <th className={"w-auto"}>Email de notification</th>
                            <th className={"w-auto"}>Statut</th>
                            <th style={{width:"10%"}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>

                        {accounts.map((acc: CompteWally) => lineAccount(acc))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}
