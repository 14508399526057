import { MarqueWally } from "./MarqueWally"
import { OrigineWally } from "./OrigineWally"
import { Origine } from "../../../../../types/Origine"
import { notAddedMapping } from "../titleMapping"

export class OrigineMarqueWally
{
    IDOrigineAction!: number | null
    IdWallyMarque!: number
    NomMarque!:string
    NomOrigine!:string
    IdWallyOrigine!: number
    marque?: MarqueWally
    origine?: OrigineWally
    origineESBO?: Origine
    isCurrentChange?: boolean
    toSkip?: boolean
    IDOrigineActionModifying?: number | null

    constructor(data?: Partial<OrigineMarqueWally>, originesESBO?: Origine[], marques?: MarqueWally[], origines?: OrigineWally[]) {
        Object.assign(this, data)
        if (this.IdWallyMarque && marques) {
            this.marque = marques.find(m => m.IdWallyMarque === this.IdWallyMarque)
        }
        if (this.IdWallyOrigine && origines) {
            this.origine = origines.find(o => o.IdWallyOrigine === this.IdWallyOrigine)
        }

        if (this.IDOrigineAction && originesESBO) {
            this.origineESBO = originesESBO.find(oes => oes.IDOrigineAction === this.IDOrigineAction)
        }
        //console.log( this.origineESBO)
        this.isCurrentChange = this.IDOrigineAction === null;
        this.IDOrigineActionModifying = this.IDOrigineAction
        if(this.toSkip === undefined) {
            this.toSkip = false;
        }
        if(this.toSkip) {
            this.IDOrigineActionModifying = notAddedMapping["ID"];
        }

        this.isCurrentChange = this.IDOrigineAction === null && !this.toSkip;
    }

    getPrimaryKey = () => {
        return this.IdWallyOrigine + "_" + this.IdWallyMarque
    }

    getESellerId = () => {
        if(this.toSkip){
            return notAddedMapping["ID"]
        }
       return this.IDOrigineAction
    }


    getTitleWally = () => {
        return this.NomOrigine
    }
    getTitleMarque = () => {
        return this.NomMarque
    }

    getTitleESeller = () => {
        if(this.toSkip){
            return notAddedMapping["TITLE"]
        }
        return this.origineESBO?.LibelleFamille + " - " +
            this.origineESBO?.LibelleSousFamille + " - " +
            this.origineESBO?.LibelleOrigine + " ("+
            this.origineESBO?.DescOrigineActionType + ")"
    }
    getIdModifying = () => {
        return this.IDOrigineActionModifying
    }
    setIdModifying = (id:number|null|undefined) => {
        return this.IDOrigineActionModifying = id;
    }
    resetIdModifying = () => {
        this.IDOrigineActionModifying = this.IDOrigineAction;
        if(this.toSkip) {
            this.IDOrigineActionModifying = notAddedMapping["ID"];
        }
        return this.IDOrigineActionModifying;
    }

    getUrlToSave = (account_id:string) =>
         '/interfaces/wally/accounts/' + account_id + "/marques-origines/" + this.IdWallyOrigine + "/" + this.IdWallyMarque

    getUrlToDelete = (account_id:string) =>
         '/interfaces/wally/accounts/' + account_id + "/marques-origines/" + this.IdWallyOrigine + "/" + this.IdWallyMarque


    getDataToSave = () => {
        return { idOrigineAction: this.getIdModifying() }
    }
}

