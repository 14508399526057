import React, { useEffect, useMemo, useRef, useState } from 'react'
import Select from 'react-select'

export default function GroupeConfig(props: any) {

    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const [ fileCGV, setFileCGV ] = useState<any>({})

    const getSelectedTemplatesOffre = () => {
        return props.configs.filter((c: any) => c.Categorie == "offre" && c.IDSite === null).map((c: any) => c.IDTemplateImpression)
    }

    const getSelectedTemplatesBdc = () => {
        return props.configs.filter((c: any) => c.Categorie == "bdc" && c.IDSite === null).map((c: any) => c.IDTemplateImpression)
    }

    const getSelectedTemplatesBonEssai = () => {
        return props.configs.filter((c: any) => c.Categorie == "bonEssai" && c.IDSite === null).map((c: any) => c.IDTemplateImpression)
    }

    const onSelectCGV = (event: any) => {
        const fileCGV = {}
        const reader = new FileReader()
        reader.onload = (e) => {
            setFileCGV({
                ...fileCGV,
                file: reader.result,
                Document: event.target.files[0].name,
                Nom: event.target.files[0].name.split(".pdf")[0],
            })
        }
        reader.readAsDataURL(event.target.files[0])
    }

    const isFileAlreadyExist = useMemo(() => {
        return props.templatesImpressionCGVList.some((t: any) => t.Document === fileCGV.Document)
    }, [ fileCGV, props.templatesImpressionCGVList ])

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "" // ✅ Réinitialiser l'input file
        }
    }

    useEffect(() => {
        if (isFileAlreadyExist) {
            setFileCGV({ ...fileCGV, Nom: "", file: "" })
            resetFileInput()
        }
    }, [ isFileAlreadyExist ])

    console.log(fileCGV)

    return (
        <div>
            <div className="alert alert-primary">
                <i className="fa fa-info-circle mr-2"/>
                Configuration globale du groupe. Les templates sélectionnés ici sont disponibles pour tous les sites.
            </div>

            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col" className="border-0" style={{ width: '50%' }}>Templates Offre</th>
                    <th scope="col" className="border-0" style={{ width: '50%' }}>Templates Bon de commande</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <Select multi
                                onChange={() => props.onChange(null, "offre")}
                                value={getSelectedTemplatesOffre()}
                                options={props.templatesImpressionOffreOptList}
                                searchable={true}
                                placeholder="..."
                        />
                    </td>
                    <td>
                        <Select multi
                                onChange={() => props.onChange(null, "bdc")}
                                value={getSelectedTemplatesBdc()}
                                options={props.templatesImpressionBdcOptList}
                                searchable={true}
                                placeholder="..."
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col" className="border-0" style={{ width: '50%' }}>Templates Bon d'Essai</th>
                    <th scope="col" className="border-0" style={{ width: '50%' }}>CGV</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <Select multi
                                onChange={() => props.onChange(null, "bonEssai")}
                                value={getSelectedTemplatesBonEssai()}
                                options={props.templatesImpressionBonEssaiOptList}
                                searchable={true}
                                placeholder="..."
                        />
                    </td>
                    <td>
                        <div className={"row mx-2"}>
                            {props.templatesImpressionCGVList.map((template: any) => {
                                return (
                                    <button
                                        disabled={isFileAlreadyExist}
                                        type="button"
                                        className="btn btn-info btn-sm mx-2"
                                        onClick={() => props.deleteTemplateCGV(template.Nom)}
                                    >
                                        <i className="fa fa-trash mr-2"></i>{template.Nom}
                                    </button>
                                )
                            })}
                        </div>
                        <div className={"row align-items-end"}>
                            <div className={"col"}>
                                <span>Ajouter une CGV</span>
                                <input ref={fileInputRef} onChange={(e) => onSelectCGV(e)} accept=".pdf" type="file" className="form-control" id="CGVBinary" name="CGVBinary"/>
                            </div>
                            <div className={"col"}>
                                <span>Nom du fichier</span>
                                <input disabled={isFileAlreadyExist} onChange={(e) => setFileCGV({
                                    ...fileCGV, Nom: e.currentTarget.value,
                                })} type="text" className="form-control form-control-sm" id="Nom" name="Nom" value={fileCGV.Nom}/>
                            </div>
                            <div className={"col"}>
                                <button
                                    disabled={isFileAlreadyExist}
                                    type="button"
                                    className="btn btn-dark btn-sm"
                                    onClick={() => {
                                        props.addTemplateCGV(fileCGV)
                                        setFileCGV({ Nom: "", Document: "", file: "" })
                                        resetFileInput()
                                    }}
                                >
                                    <i className="fa fa-plus"></i> Ajouter
                                </button>
                            </div>
                        </div>
                        {isFileAlreadyExist &&
                            <div className="alert alert-danger" role="alert">Un document avec ce nom existe déjà. Veuillez choisir un autre fichier</div>
                        }
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )

}
